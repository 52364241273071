<template>
  <section>
    <e-filters
      ref="filters"
      :searching="fetching"
      @search="filterData"
      @reset="resetFiltersLocal"
    >
      <div>
        <b-row>
          <b-col md="3">
            <FormulateInput
              id="service-request-id"
              v-model="filters.id"
              name="serviceRequestId"
              :label="$t('Id da solicitação')"
              type="text"
            />
          </b-col>
          <b-col md="3">
            <FormulateInput
              id="service-request-status"
              v-model="filters.status"
              multiple
              :label="$t('Status')"
              :placeholder="$t('Todos')"
              type="vue-select"
              :options="[
                {
                  label: $t('ServiceRequest.Status.InProgress'),
                  value: 'InProgress',
                },
                {
                  label: $t('ServiceRequest.Status.Rejected'),
                  value: 'Rejected',
                },
                {
                  label: $t('ServiceRequest.Status.Completed'),
                  value: 'Completed',
                },
                {
                  label: $t('ServiceRequest.Status.Canceled'),
                  value: 'Canceled',
                },
              ]"
            />
          </b-col>
          <b-col md="6">
            <FormulateInput
              id="period"
              v-model="filters.rangeDate"
              type="date-range-picker"
              :label="$t('Solicitado em')"
            />
          </b-col>
        </b-row>
        <b-row>
          <b-col md="4">
            <e-user-search
              id="user_id"
              v-model="filters.userId"
              :label="$t('Usuário')"
              name="user_id"
            />
          </b-col>
          <b-col md="4">
            <e-store-combo
              id="order-store_id"
              v-model="filters.storeId"
              :required="false"
            />
          </b-col>
        </b-row>
      </div>
      <template #after_buttons>
        <e-button
          class="ml-1"
          :busy="fetching"
          :text="$t('Baixar Relatório')"
          variant="primary"
          icon="download"
          @click="downloadReport"
        />
      </template>
    </e-filters>

    <b-card-actions
      action-refresh
      :show-loading="fetching"
      @refresh="getData"
    >
      <div slot="title">
        <e-page-size-selector
          :per-page="paging.pageSize"
          @change="pageSizeChange"
        />
      </div>

      <b-table
        id="service-request-table"
        show-empty
        responsive
        striped
        class="bordered"
        :empty-text="getEmptyTableMessage($tc('Service Request'))"
        :fields="fields"
        :items="serviceRequests"
        no-local-sorting
        :sort-by.sync="sorting.sortBy"
        :sort-desc.sync="sorting.sortDesc"
        @sort-changed="getData"
      >
        <template #cell(action)="{ item }">
          <e-grid-actions
            :show-update="false"
            :show-delete="false"
            :show-read-only="$can('Read', 'ServiceRequest')"
            :buttons="
              item.status == 'InProgress'
                ? [
                  {
                    icon: 'x-circle',
                    variant: 'danger',
                    title: $t('Cancelar Solicitação'),
                    event: 'service-request-cancel',
                  },
                ]
                : []
            "
            @read-only="onDetailServiceRequest(item)"
            @service-request-cancel="onCancelServiceRequest(item)"
          />
        </template>
      </b-table>
      <b-row class="pt-1">
        <b-col>
          <e-pagination-summary
            :current-page="paging.currentPage"
            :per-page="paging.pageSize"
            :total="paging.rowCount"
            :total-on-page="paging.rowsInCurrentPage"
          />
        </b-col>
        <b-col>
          <b-pagination
            v-model="paging.currentPage"
            align="right"
            :total-rows="paging.rowCount"
            :per-page="paging.pageSize"
            aria-controls="service-request-table"
            @change="pageChange"
          />
        </b-col>
      </b-row>
    </b-card-actions>

    <fab
      v-if="$can('Create', 'ServiceRequest')"
      :main-tooltip="$t('Adicionar Solicitação')"
      @click="onCreateServiceRequest"
    />

    <div class="pb-1" />

    <e-modal-service-request-cancelation
      ref="modalCancelation"
      @after-confirm="$router.push({ name: 'service-request-list' })"
    />
  </section>
</template>

<script>
import moment from 'moment'
import { BRow, BCol, BPagination, BTable } from 'bootstrap-vue'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import {
  EPageSizeSelector,
  EPaginationSummary,
  EFilters,
  EGridActions,
  EStoreCombo,
  EUserSearch,
  EButton,
} from '@/views/components'
import fab from '@/views/components/FAB.vue'
import { downloader, serviceTypes } from '@/mixins'
import { mapActions, mapState } from 'vuex'
import EModalServiceRequestCancelation from './EModalServiceRequestCancelation.vue'

export default {
  components: {
    BRow,
    BCol,
    BCardActions,
    BPagination,
    BTable,
    EPaginationSummary,
    EPageSizeSelector,
    fab,
    EFilters,
    EGridActions,
    EUserSearch,
    EStoreCombo,
    EModalServiceRequestCancelation,
    EButton,
  },

  mixins: [serviceTypes, downloader],

  data() {
    return {
      fetching: false,
    }
  },

  computed: {
    ...mapState('pages/requests/serviceRequest', [
      'serviceRequests',
      'paging',
      'sorting',
      'filters',
    ]),

    fields() {
      return [
        {
          label: this.$t('Ações'),
          key: 'action',
          tdClass: 'text-center',
          thStyle: { width: '100px' },
        },
        {
          label: this.$t('Id'),
          key: 'id',
          tdClass: 'text-center',
          thStyle: { width: '80px' },
          sortable: true,
        },
        {
          label: this.$t('Area'),
          key: 'serviceArea.name',
          sortable: true,
        },
        {
          label: this.$t('Beneficiário'),
          key: 'store',
          sortable: false,
          formatter: (value, index, item) => item.store?.name ?? item.user?.name,
        },
        {
          label: this.$t('Categoria'),
          key: 'serviceCategory.name',
          sortable: true,
        },
        {
          label: this.$t('Status'),
          key: 'status',
          formatter: (value, index, item) =>
            `${this.statusServiceRequest[value]}${
              item.currentStep ? ` (${item.currentStep})` : ''
            }`,
          sortable: true,
        },
        {
          label: this.$t('Criado em'),
          key: 'createdDate',
          formatter: value => this.$options.filters.datetime(value),
          sortable: true,
        },
        {
          label: this.$t('Solicitado por'),
          key: 'createdBy',
          sortable: true,
        },
      ]
    },
  },

  mounted() {
    this.getData()
  },

  methods: {
    ...mapActions('pages/requests/serviceRequest', [
      'fetchServiceRequests',
      'resetFilters',
      'setCurrentPage',
      'setPageSize',
    ]),

    async getData() {
      this.$nextTick(async () => {
        try {
          this.fetching = true
          await this.fetchServiceRequests()
        } catch (error) {
          this.showGenericError({ error })
        } finally {
          this.fetching = false
        }
      })
    },

    async onDetailServiceRequest(row) {
      this.$router.push({
        name: 'service-request-details',
        params: { id: row.id },
      })
    },

    async onCancelServiceRequest(row) {
      console.log(row)
      this.$refs.modalCancelation.show(row.id, row)
    },

    filterData() {
      this.setCurrentPage(1)
      this.getData()
    },
    resetFiltersLocal() {
      this.resetFilters()
      this.setCurrentPage(1)
      this.getData()
    },
    pageSizeChange(pageSize) {
      this.setPageSize(pageSize)
      this.getData()
    },
    pageChange(currentPage) {
      this.setCurrentPage(currentPage)
      this.getData()
    },

    onCreateServiceRequest() {
      this.$router.push({
        name: 'service-request-create',
      })
    },
    async downloadReport() {
      try {
        if (this.$refs.filters.isValid()) {
          this.fetching = true
          const data = await this.fetchServiceRequests(true)

          if (data) {
            this.forceFileDownload(data, `Solicitacoes_${moment().format()}.xlsx`)
          } else {
            this.showError({ message: this.$t('Não foi possível recuperar os dados do relatório') })
          }
        }
      } catch (error) {
        this.showError({ error })
      } finally {
        this.fetching = false
      }
    },
  },
}
</script>
