var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-row",
        [
          _c(
            "b-col",
            [
              _vm.stores != null && _vm.stores.length > 1
                ? _c("FormulateInput", {
                    staticClass: "required",
                    attrs: {
                      id: "store-name",
                      type: "vue-select",
                      label: _vm.$t("Loja"),
                      placeholder: _vm.$t("Selecione"),
                      options: _vm.storeOptions,
                      validation: "required",
                    },
                    model: {
                      value: _vm.filters.storeId,
                      callback: function ($$v) {
                        _vm.$set(_vm.filters, "storeId", $$v)
                      },
                      expression: "filters.storeId",
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "b-row",
        { staticClass: "match-height" },
        _vm._l(_vm.beerTaps, function (beerTap, index) {
          return _c(
            "b-col",
            { key: beerTap.tapNumber, attrs: { md: "6", lg: "3" } },
            [
              _c(
                "b-card-actions",
                {
                  attrs: {
                    "img-src": _vm.barrelImage(beerTap) || _vm.noImage,
                    "img-alt": "Card image cap",
                    "img-top": "",
                    "no-actions": "",
                    "show-loading": _vm.fetching || _vm.saving,
                    title:
                      "#" + beerTap.tapNumber + " - " + _vm.barrelName(beerTap),
                  },
                },
                [
                  _c(
                    "h3",
                    [
                      _c(
                        "b-badge",
                        {
                          attrs: {
                            href: "#",
                            variant:
                              beerTap.status === "Active"
                                ? "success"
                                : "danger",
                          },
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t(
                                  beerTap.status === "Active"
                                    ? "Ativa"
                                    : "Inativa"
                                )
                              ) +
                              " "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c("vue-apex-charts", {
                    attrs: {
                      type: "radialBar",
                      height: "245",
                      options: _vm.goalOverviewRadialBar.chartOptions,
                      series: beerTap.series,
                    },
                  }),
                  _c(
                    "b-row",
                    { staticClass: "text-center mx-0" },
                    [
                      _c(
                        "b-col",
                        {
                          staticClass:
                            "border-top border-right d-flex align-items-between flex-column py-1",
                          attrs: { cols: "6" },
                        },
                        [
                          _c(
                            "b-card-text",
                            { staticClass: "text-muted mb-0" },
                            [_vm._v(" " + _vm._s(_vm.$t("Capacidade")) + " ")]
                          ),
                          _c(
                            "h3",
                            { staticClass: "font-weight-bolder mb-50" },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm._f("number")(
                                      _vm.barrelCapacity(beerTap),
                                      2
                                    )
                                  ) +
                                  " L "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "b-col",
                        {
                          staticClass:
                            "border-top d-flex align-items-between flex-column py-1",
                          attrs: { cols: "6" },
                        },
                        [
                          _c(
                            "b-card-text",
                            { staticClass: "text-muted mb-0" },
                            [_vm._v(" " + _vm._s(_vm.$t("Restante")) + " ")]
                          ),
                          _c("h3", { staticClass: "font-weight-bolder mb-0" }, [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm._f("number")(
                                    beerTap.remainingLiquid / 1000,
                                    2
                                  )
                                ) +
                                " L "
                            ),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "b-row",
                    { staticClass: "text-center mx-0" },
                    [
                      _c(
                        "b-col",
                        {
                          staticClass:
                            "border-top d-flex align-items-between flex-column py-1",
                          attrs: { cols: "12" },
                        },
                        [
                          _c(
                            "b-card-text",
                            { staticClass: "text-muted mb-0" },
                            [
                              _vm._v(
                                " " + _vm._s(_vm.$t("Preço por Litro")) + " "
                              ),
                            ]
                          ),
                          _c(
                            "h3",
                            { staticClass: "font-weight-bolder mb-50" },
                            [
                              !_vm.localBeerTaps[index].editingPrice
                                ? _c("span", [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm._f("currency")(
                                            beerTap.currentPrice
                                          )
                                        ) +
                                        " "
                                    ),
                                  ])
                                : _c("FormulateInput", {
                                    key: beerTap.tapNumber,
                                    attrs: {
                                      id: "price-tap-" + beerTap.tapNumber,
                                      type: "text-number",
                                      filter: "currency",
                                      currency: "R$",
                                      precision: 2,
                                      validation: "^required|min:0.01",
                                      "validation-messages": {
                                        required: _vm.$t(
                                          "O preço é obrigatório"
                                        ),
                                        min: _vm.$t("Valor inválido"),
                                      },
                                    },
                                    model: {
                                      value: beerTap.currentPrice,
                                      callback: function ($$v) {
                                        _vm.$set(beerTap, "currentPrice", $$v)
                                      },
                                      expression: "beerTap.currentPrice",
                                    },
                                  }),
                              beerTap.barrelInstalled &&
                              !_vm.localBeerTaps[index].editingPrice &&
                              _vm.beerCoolerIntegration != "EasyChope"
                                ? _c("b-icon", {
                                    staticClass: "cursor-pointer",
                                    attrs: {
                                      icon: "pencil",
                                      variant: "primary",
                                      "font-scale": "1",
                                      title: _vm.$t("Alterar preço"),
                                    },
                                    on: {
                                      click: function ($event) {
                                        _vm.localBeerTaps[
                                          index
                                        ].editingPrice = true
                                      },
                                    },
                                  })
                                : _vm._e(),
                              beerTap.barrelInstalled &&
                              _vm.localBeerTaps[index].editingPrice
                                ? _c(
                                    "div",
                                    [
                                      _c("e-button", {
                                        staticClass: "cursor-pointer",
                                        attrs: {
                                          icon: "check",
                                          variant: "primary",
                                          "font-scale": "1",
                                          title: _vm.$t("Confirmar"),
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.updatePrice(
                                              beerTap,
                                              index
                                            )
                                          },
                                        },
                                      }),
                                      _c("e-button", {
                                        staticClass: "cursor-pointer ml-1",
                                        attrs: {
                                          icon: "x",
                                          variant: "danger",
                                          "font-scale": "1",
                                          title: _vm.$t("Cancelar"),
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.cancelUpdatePrice(index)
                                          },
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "mt-1" },
                    [
                      _c(
                        "b-button",
                        {
                          attrs: { variant: "outline-primary" },
                          on: {
                            click: function ($event) {
                              return _vm.addBarrel(beerTap)
                            },
                          },
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("Trocar Barril")) + " ")]
                      ),
                      beerTap.status === "Active"
                        ? _c(
                            "b-button",
                            {
                              staticClass: "ml-1",
                              attrs: { variant: "outline-danger" },
                              on: {
                                click: function ($event) {
                                  return _vm.activateOrInactivateBeerTap(
                                    beerTap,
                                    false
                                  )
                                },
                              },
                            },
                            [_vm._v(" " + _vm._s(_vm.$t("Inativar")) + " ")]
                          )
                        : _c(
                            "b-button",
                            {
                              staticClass: "ml-1",
                              attrs: { variant: "primary" },
                              on: {
                                click: function ($event) {
                                  return _vm.activateOrInactivateBeerTap(
                                    beerTap,
                                    true
                                  )
                                },
                              },
                            },
                            [_vm._v(" " + _vm._s(_vm.$t("Ativar")) + " ")]
                          ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        }),
        1
      ),
      _c("modal-barrel-search", {
        ref: "modalBarrelSearch",
        attrs: { "beer-tap": _vm.selectedBeerTap },
        on: { "add-barrel": _vm.onAddItem },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }