<template>
  <section class="container-close">
    <b-container>
      <FormulateForm
        name="formPayBoxClose"
        @submit="onSubmitClose"
      >
        <b-row>
          <b-col md="12">
            <p class="h1 text-primary my-1">
              Fechamento do caixa
            </p>
          </b-col>

          <b-col md="12">
            <p class="h3">
              Resumo do Caixa
            </p>
          </b-col>
        </b-row>

        <b-row>
          <b-col md="6">
            <b-card-actions no-actions>
              <template #title>
                <p class="h4 mb-0 text-info">
                  Total das Entradas
                </p>
              </template>

              <b-col md="12">
                <div class="container-box">
                  <box-line
                    is-header
                    :show-estimated-value="showEstimatedValues"
                  />

                  <box-line
                    v-for="credit in localSummaryCreditViewPermission"
                    :key="`credit_${credit.origin}_${credit.paymentMethod.id}_${credit.online}`"
                    :title="getCreditLabel(credit)"
                    :estimated-value="credit.total"
                    :show-estimated-value="showEstimatedValues"
                  >
                    <FormulateInput
                      v-if="credit.readOnly || credit.online"
                      :id="`input-credit-${credit.origin}_${credit.paymentMethod.id}_${credit.online}`"
                      v-model="credit.totalTyped"
                      type="label"
                      input-class="text-right"
                      filter="currency"
                    />
                    <FormulateInput
                      v-else
                      :id="`input-credit-${credit.origin}_${credit.paymentMethod.id}_${credit.online}`"
                      v-model="credit.totalTyped"
                      type="text-number"
                      input-class="text-right"
                      currency="R$"
                      :precision="2"
                      @input="onInputTotalsCredit"
                    />
                  </box-line>

                  <box-line
                    :title="'Total'"
                    class="font-weight-bold"
                    :show-estimated-value="showEstimatedValues"
                    :estimated-value="getTotalsEstimated.credit"
                  >
                    <p class="h4 text-dark">
                      {{ totalsTypedCreditViewPermission | currency }}
                    </p>
                  </box-line>

                  <box-line
                    v-if="showEstimatedValues"
                    :title="'Total [Em dinheiro]'"
                    class="font-weight-bold"
                    :show-estimated-value="showEstimatedValues"
                    :estimated-value="getTotalsEstimated.creditCash"
                  >
                    <p class="h4 text-dark">
                      {{ totalsTypedCreditCashViewPermission | currency }}
                    </p>
                  </box-line>
                </div>
              </b-col>
            </b-card-actions>
          </b-col>

          <b-col md="6">
            <b-card-actions no-actions>
              <template #title>
                <p class="h4 mb-0 text-danger">
                  Total das Saídas
                </p>
              </template>

              <b-col md="12">
                <div class="container-box">
                  <box-line
                    is-header
                    :show-estimated-value="showEstimatedValues"
                  />

                  <box-line
                    v-for="debit in localSummaryDebit"
                    :key="`debit_${debit.origin}_${debit.paymentMethod.id}`"
                    :title="`${saleOriginEnumLabel[debit.origin]} [${debit.paymentMethod.name}]`"
                    :estimated-value="debit.total"
                    :show-estimated-value="showEstimatedValues"
                  >
                    <FormulateInput
                      :id="`input-debit-${debit.origin}_${debit.paymentMethod.id}`"
                      v-model="debit.totalTyped"
                      type="text-number"
                      input-class="text-right"
                      currency="R$"
                      :precision="2"
                      @input="onInputTotalsDebit"
                    />
                  </box-line>

                  <box-line
                    :title="'Total'"
                    class="font-weight-bold"
                    :show-estimated-value="showEstimatedValues"
                    :estimated-value="getTotalsEstimated.debit"
                  >
                    <p class="h4 text-dark">
                      {{ totalsTyped.debit | currency }}
                    </p>
                  </box-line>

                  <box-line
                    :title="'Total [Em dinheiro]'"
                    class="font-weight-bold"
                    :show-estimated-value="showEstimatedValues"
                    :estimated-value="getTotalsEstimated.debitCash"
                  >
                    <p class="h4 text-dark">
                      {{ totalsTyped.debitCash | currency }}
                    </p>
                  </box-line>
                </div>
              </b-col>
            </b-card-actions>
          </b-col>
        </b-row>

        <b-row>
          <b-col>
            <b-card-actions no-actions>
              <template #title>
                <p class="h4 mb-0 text-primary">
                  Saldo Caixa [Em Dinheiro]
                </p>
              </template>

              <b-col md="12">
                <div class="container-box">
                  <box-line
                    is-header
                    :show-estimated-value="showEstimatedValues"
                  />

                  <box-line :title="$t('Troco inicial caixa')">
                    <FormulateInput
                      id="pay_box_close-opening_balance"
                      v-model="openingBalance"
                      name="openingBalance"
                      filter="currency"
                      type="label"
                      input-class="text-right"
                    />
                  </box-line>

                  <box-line
                    :title="$t('Troco final caixa')"
                    :show-estimated-value="showEstimatedValues"
                    :estimated-value="getTotalsEstimated.finalPayBoxChange"
                  >
                    <FormulateInput
                      :id="`input_totals-credit_final_pay_box_change`"
                      v-model="finalPayBoxChange"
                      name="finalPayBoxChange"
                      type="text-number"
                      input-class="text-right"
                      currency="R$"
                      :precision="2"
                      @input="onInpuTotals"
                    />
                  </box-line>

                  <box-line
                    :title="$t('Sangria Final')"
                    :show-estimated-value="showEstimatedValues"
                    :estimated-value="getTotalsEstimated.finalBleedingValue"
                  >
                    <FormulateInput
                      :id="`input_totals-debit_final_bleeding`"
                      v-model="finalBleeding"
                      name="finalBleeding"
                      type="text-number"
                      input-class="text-right"
                      currency="R$"
                      :precision="2"
                      @input="onInpuTotals"
                    />
                  </box-line>

                  <box-line
                    v-if="showEstimatedValues"
                    :title="'Saldo final caixa'"
                    :show-estimated-value="showEstimatedValues"
                    :estimated-value="getTotalsEstimated.cashTotalBalance"
                    :instruction="$t('CASH_BOOK.CLOSE.UI.FINAL_BALANCE')"
                  >
                    <p
                      :class="{
                        h4: true,
                        'text-dark': cashTotalBalance === 0,
                        'text-success': cashTotalBalance < 0,
                        'text-danger': cashTotalBalance > 0,
                      }"
                    >
                      {{ cashTotalBalance | currency }}
                    </p>
                  </box-line>
                </div>
              </b-col>
            </b-card-actions>
          </b-col>
        </b-row>

        <b-row v-if="isForcedClose">
          <b-col>
            <b-card-actions
              :title="$t('Outras informações')"
              no-actions
            >
              <b-col md="12">
                <b-alert
                  show
                  variant="danger"
                  class="p-2"
                >
                  {{ $t(divergenceMessage) }}
                </b-alert>
              </b-col>
              <b-col md="12">
                <FormulateInput
                  id="pay_box_close-observation"
                  v-model="forcedForm.observation"
                  name="observation"
                  type="textarea"
                  :label="$t('Observação')"
                  class="required"
                  validation="required|min:10"
                />
              </b-col>

              <b-col
                md="12"
                class="d-flex justify-content-end align-items-center"
              >
                <e-button
                  :text="$t('Ver extrato')"
                  @click="showStatementSidebar"
                />
              </b-col>
            </b-card-actions>
          </b-col>
        </b-row>

        <b-row class="pb-2">
          <b-col>
            <e-button
              :text="$t('Voltar')"
              block
              @click="() => $router.back()"
            />
          </b-col>
          <b-col>
            <e-button
              type="submit"
              variant="primary"
              :text="$t('Confirmar Fechamento')"
              block
            />
          </b-col>
        </b-row>
      </FormulateForm>
    </b-container>

    <!-- <modal-confirm-value
      ref="modalConfirmValue"
      :total-value="cashTotalBalance"
      :is-advanced-user="isForcedClose"
    /> -->

    <statement-sidebar
      v-if="isForcedClose"
      ref="statementSidebar"
    />
  </section>
</template>

<script>
/* eslint-disable func-names */
import { BContainer, BRow, BCol, BAlert } from 'bootstrap-vue'
import delegablePermissions from '@/utils/delegable-permissions'
import _ from 'lodash'
import { EButton } from '@/views/components'
import BCardActions from '@/@core/components/b-card-actions/BCardActions.vue'
import { loadingOverlay, payBoxTypes, paymentTypes } from '@/mixins'
import { mapActions, mapGetters, mapState } from 'vuex/'
import BoxLine from './components/BoxLine.vue'
import StatementSidebar from '../pay-box-statement/StatementSidebar.vue'

export default {
  name: 'PayBoxClose',
  components: {
    BContainer,
    BRow,
    BCol,
    BAlert,
    BoxLine,
    BCardActions,
    EButton,
    StatementSidebar,
  },

  mixins: [paymentTypes, payBoxTypes, loadingOverlay],

  data() {
    return {
      delegateUserData: null,
      localSummaryCredit: [],
      localSummaryDebit: [],
      totalsTyped: {
        credit: 0,
        creditCash: 0,
        debit: 0,
        debitCash: 0,
      },
      openingBalance: 0,
      finalPayBoxChange: 0,
      cashTotalBalance: 0,
      finalBleeding: 0,
      forcedForm: {
        observation: '',
      },
      divergenceMessage: '',
      isForcedClose: false,
    }
  },

  computed: {
    ...mapState('app', ['modalAuthRef']),
    ...mapState('pages/pdv', ['cashBookDetail']),
    ...mapGetters('pages/pdv', ['summaryCreditsFormatted', 'summaryDebitsFormatted']),
    ...mapGetters('pages/pdv/payBoxConfiguration', ['thermalPrinterAgentConfigPayload']),

    showEstimatedValues() {
      return this.isForcedClose || this.$can('CloseViewingEstimatedValues', 'CashBook')
    },
    localSummaryCreditViewPermission() {
      return this.localSummaryCredit.filter(c => this.showEstimatedValues || !c.readOnly)
    },

    totalsTypedCreditViewPermission() {
      const cashInformedReadOnly = this.localSummaryCredit
        .filter(c => this.showEstimatedValues && c.readOnly)
        .filter(c => c.paymentMethod.method === this.paymentTypeEnum.CASH)
        .reduce((total, c) => total + c.totalTyped, 0)

      return this.totalsTyped.credit + cashInformedReadOnly
    },

    totalsTypedCreditCashViewPermission() {
      const cashInformedReadOnly = this.localSummaryCredit
        .filter(c => this.showEstimatedValues && c.readOnly)
        .filter(c => c.paymentMethod.method === this.paymentTypeEnum.CASH)
        .reduce((total, c) => total + c.totalTyped, 0)

      return this.totalsTyped.creditCash + cashInformedReadOnly
    },

    getTotalsEstimated() {
      const { openingBalance } = this.cashBookDetail
      const credit = this.localSummaryCredit.reduce((total, c) => total + c.total, 0)

      const creditCash = this.localSummaryCredit
        .filter(c => c.paymentMethod.method === this.paymentTypeEnum.CASH)
        .reduce((total, c) => total + c.total, 0)

      const debit = this.localSummaryDebit.reduce((total, d) => total + d.total, 0)
      const debitCash = this.localSummaryDebit
        .filter(c => c.paymentMethod.method === this.paymentTypeEnum.CASH)
        .reduce((total, d) => total + d.total, 0)

      const finalPayBoxChange = openingBalance

      const finalBleedingValue = creditCash - debitCash

      const cashTotalBalance = creditCash - debitCash - finalBleedingValue

      return {
        credit,
        creditCash,
        debit,
        debitCash,
        finalPayBoxChange,
        finalBleedingValue,
        cashTotalBalance,
      }
    },
  },

  watch: {
    'totalsTyped.debitCash': function () {
      this.updateFinalBalance()
    },
    'totalsTyped.creditCash': function () {
      this.updateFinalBalance()
    },
  },

  async mounted() {
    try {
      this.showLoadingOverlay()
      await this.stFetchCashBookDetail()

      this.localSummaryCredit = this.summaryCreditsFormatted.map(c => {
        // TODO parametrizar dinherio e pagamentos online
        if (c.origin === this.saleOriginEnum.SALE && c.paymentMethod.id === 1) {
          return {
            ...c,
            totalTyped: c.total,
            online: false,
            readOnly: true,
          }
        }

        if (c.origin === this.saleOriginEnum.SALE && c.paymentMethod.name.indexOf('online') > 0) {
          return {
            ...c,
            totalTyped: c.total,
            online: true,
          }
        }

        return {
          ...c,
          online: false,
        }
      })
      this.localSummaryDebit = this.summaryDebitsFormatted

      this.openingBalance = this.cashBookDetail.openingBalance || 0
      this.onInputTotalsCredit()
      this.updateFinalBalance()
    } catch (error) {
      if (error?.response?.status === 404) {
        if (this.$router.currentRoute.name !== 'pdv-pay-box-open') {
          this.$router.push({ name: 'pdv-pay-box-open' })
        }
      } else {
        this.showError({ error })
      }
    } finally {
      this.hideLoadingOverlay()
    }
  },

  methods: {
    ...mapActions('pages/pdv', {
      stClosePdv: 'closePdv',
      stFetchCashBookDetail: 'fetchCashBookDetail',
    }),
    ...mapActions('pages/pdv/payBoxPrint', ['printCloseCashBook']),

    getCreditLabel(credit) {
      let nameOrigin = this.saleOriginEnumLabel[credit.origin]
      if (credit.origin === this.saleOriginEnum.BLEEDING) {
        nameOrigin = this.$t('Estorno de sangria')
      }
      return `${nameOrigin} [${credit.paymentMethod.name}]`
    },

    showStatementSidebar() {
      this.$refs.statementSidebar.show()
    },

    async onSubmitClose() {
      try {
        this.showLoadingOverlay(this.$t('Finalizando'))

        const closeData = await this.stClosePdv({
          finalPayBoxChange: this.finalPayBoxChange,
          summaries: [...this.localSummaryCredit, ...this.localSummaryDebit],
          observation: this.forcedForm.observation,
          delegateUserData: this.delegateUserData,
          isForced: this.isForcedClose,
          finalBleeding: this.finalBleeding,
        })

        this.showLoadingOverlay(this.$t('CASH_BOOK.PRINTING_CLOSING_RECEIPT'))
        await this.printCloseCashBook({ closeData })

        this.showSuccess({ message: 'sucesso' })
        this.$router.push({ name: 'pdv-pay-box-open' })
      } catch (error) {
        if (error.response?.status === 400) {
          const errors = Object.values(error.response?.data || []).flatMap(i => i)
          if (errors) {
            // eslint-disable-next-line prefer-destructuring
            this.divergenceMessage = errors[0]
          }
          if (!this.isForcedClose) {
            this.hideLoadingOverlay()
            await this.confirmRevision()
          } else {
            this.showError({ error })
          }
        } else {
          this.showError({ error })
        }
      } finally {
        this.hideLoadingOverlay()
      }
    },

    async confirmRevision() {
      try {
        const confirmed = await this.confirm({
          title: this.$t('Aviso'),
          text: `Há divergência com os valores estimados`,
          confirmButtonText: this.$t('Corrigir valores'),
          cancelButtonText: this.$t('Prosseguir'),
        })

        if (!confirmed) {
          this.delegateUserData = await this.modalAuthRef.auth(
            delegablePermissions.PDV_CASH_BOOK_CLOSE_FORCE
          )
          this.isForcedClose = true
        }
      } catch (error) {
        if (error.name !== 'Cancel') {
          this.showError({ error })
        }
      }
    },

    onInpuTotals: _.debounce(function () {
      this.updateFinalBalance()
    }, 400),

    onInputTotalsCredit: _.debounce(function () {
      const summaries = this.localSummaryCredit.filter(c => !c.readOnly)
      this.totalsTyped.credit = summaries.reduce((total, c) => total + c.totalTyped, 0)
      this.totalsTyped.creditCash = summaries
        .filter(c => c.paymentMethod.method === this.paymentTypeEnum.CASH)
        .reduce((total, c) => total + c.totalTyped, 0)
    }, 400),

    onInputTotalsDebit: _.debounce(function () {
      this.totalsTyped.debit = this.localSummaryDebit.reduce((total, c) => total + c.totalTyped, 0)

      this.totalsTyped.debitCash = this.localSummaryDebit
        .filter(c => c.paymentMethod.method === this.paymentTypeEnum.CASH)
        .reduce((total, c) => total + c.totalTyped, 0)
    }, 400),

    updateFinalPayBoxChange() {
      // this.finalPayBoxChange = this.totalsTyped.creditCash - this.totalsTyped.debit
    },

    updateFinalBalance() {
      const cashInformedReadOnly = this.localSummaryCredit
        .filter(c => c.readOnly)
        .filter(c => c.paymentMethod.method === this.paymentTypeEnum.CASH)
        .reduce((total, c) => total + c.totalTyped, 0)

      const creditBalance =
        this.getTotalsEstimated.creditCash - (this.totalsTyped.creditCash + cashInformedReadOnly)
      const debitBalance = this.getTotalsEstimated.debitCash - this.totalsTyped.debitCash
      const finalBleedingBalance = this.getTotalsEstimated.finalBleedingValue - this.finalBleeding
      const finalPayBoxChangeBalance =
        this.getTotalsEstimated.finalPayBoxChange - this.finalPayBoxChange

      this.cashTotalBalance =
        creditBalance + debitBalance + finalBleedingBalance + finalPayBoxChangeBalance
    },
  },
}
</script>

<style lang="scss" scoped>
.container-close {
  max-width: 1200px;
  margin: 0 auto;
}
</style>
