const basePath = '/quiosque'

export default [
  {
    path: `${basePath}/chopeiras`,
    name: 'tap-station-list',
    component: () => import('@/views/pages/kiosk/easychope/TapStationList.vue'),
    meta: {
      pageTitle: 'Chopeiras',
      resource: 'KioskOperation',
      action: 'TapStationSetup',
      breadcrumb: [
        {
          text: 'Quiosques',
        },
        {
          text: 'Chopeiras',
          active: true,
        },
      ],
    },
  },
  {
    path: `${basePath}/torneiras`,
    name: 'beer-tap-list',
    component: () => import('@/views/pages/kiosk/beer-taps/BeerTaps.vue'),
    meta: {
      pageTitle: 'Torneiras',
      resource: 'KioskOperation',
      action: 'AssociateBarrel',
      breadcrumb: [
        {
          text: 'Quiosques',
        },
        {
          text: 'Torneiras',
          active: true,
        },
      ],
    },
  },
  {
    path: `${basePath}/barris`,
    name: 'barrel-list',
    component: () => import('@/views/pages/kiosk/barrels/BarrelList.vue'),
    meta: {
      pageTitle: 'Barris',
      resource: 'KioskOperation',
      action: 'AssociateBarrel',
      breadcrumb: [
        {
          text: 'Quiosques',
        },
        {
          text: 'Barris',
          active: true,
        },
      ],
    },
  },
  {
    path: `${basePath}/relatorio-consumo`,
    name: 'beer-tap-consumption',
    component: () => import('@/views/pages/kiosk/reports/ConsumptionReport.vue'),
    meta: {
      pageTitle: 'Relatório de Consumo',
      resource: 'KioskOperation',
      action: 'ConsumeReport',
      breadcrumb: [
        {
          text: 'Quiosques',
        },
        {
          text: 'Relatório de Consumo',
          active: true,
        },
      ],
    },
  },
]
