<template>
  <b-modal
    id="tapstation-config"
    :title="$t('Configuração da Chopeira')"
    size="lg"
    @hidden="cleanTapStation"
  >
    <div v-if="tapStationConfiguring">
      <h5>Station ID: {{ tapStationConfiguring.id }}</h5>
      <e-store-combo
        id="storeId"
        v-model="tapStationConfiguring.storeId"
        :required="true"
        :filter="(store) => store.type === 'Kiosk'"
        @input="setTapStationStoreLocal"
      />
      <b-row>
        <b-col
          v-for="relay in relays"
          :key="relay.id"
          lg="6"
        >
          <FormulateForm
            :ref="`tapForm_${relay.id}`"
            :form-name="`tapForm_${relay.id}`"
            @submit="
              saveRelayInfoLocal({
                ...relay,
                relayNumber: relay.id,
              })
            "
          >
            <b-card :title="`${$t('Relé')} ${relay.id}`">
              <FormulateInput
                :id="`screen_${relay.id}`"
                v-model="relay.screen"
                type="radio"
                :name="`screen_${relay.id}`"
                :label="$t('Posição da torneira')"
                :placeholder="$t('Selecione')"
                validation="required"
                class="required"
                :element-class="['d-flex', 'mt-1']"
                :options="[
                  { value: 'Left', label: $t('Esquerda') },
                  { value: 'Right', label: $t('Direita') },
                  { value: 'Full', label: $t('Inteira') },
                ]"
              />

              <FormulateInput
                :id="`tapNumber_${relay.id}`"
                v-model="relay.tapNumber"
                type="radio"
                :label="$t('Número da torneira')"
                :name="`tapNumber_${relay.id}`"
                :placeholder="$t('Selecione')"
                validation="required"
                class="required"
                :element-class="['d-flex', 'mt-1']"
                :options="[
                  { value: '1', label: $t('1') },
                  { value: '2', label: $t('2') },
                  { value: '3', label: $t('3') },
                  { value: '4', label: $t('4') },
                ]"
              />

              <FormulateInput
                :id="`millilitersPerPulse_${relay.id}`"
                v-model="relay.millisecondsPerPulse"
                type="text-number"
                :precision="5"
                :label="$t('Fator')"
                :placeholder="$t('Fator')"
                validation="required"
                class="required"
                @input="handleInput"
              />
              <b-row>
                <b-col md="6">
                  <e-button
                    icon="check"
                    block
                    variant="primary"
                    font-scale="1"
                    class="cursor-pointer mt-1"
                    :text="$t('Testar')"
                    :busy="fetching"
                    @click="testRelayLocal(relay.id)"
                  />
                </b-col>
                <b-col md="6">
                  <e-button
                    icon="save"
                    block
                    variant="primary"
                    type="submit"
                    font-scale="1"
                    class="cursor-pointer mt-1"
                    :text="$t('Salvar')"
                    :busy="fetching"
                  />
                </b-col>
              </b-row>
            </b-card>
          </FormulateForm>
        </b-col>
      </b-row>
    </div>
    <template #modal-footer>
      <b-row class="w-100 d-flex justify-content-between align-items-center">
        <b-col class="d-flex justify-content-end">
          <e-button
            icon="x"
            variant="outline-danger"
            font-scale="1"
            :text="$t('Cancelar')"
            :busy="fetching"
            class="mr-1"
            @click="cancelSetup()"
          />
          <e-button
            icon="check"
            variant="primary"
            font-scale="1"
            class="cursor-pointer"
            :text="$t('Finalizar configuração')"
            :busy="fetching"
            @click="finishSetupLocal()"
          />
        </b-col>
      </b-row>
    </template>
  </b-modal>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { BRow, BCol, BCard } from 'bootstrap-vue'
import { EButton, EStoreCombo } from '@/views/components'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    EButton,
    EStoreCombo,
  },
  data() {
    return {
      relays: [
        {
          id: 1,
          screen: null,
          tapNumber: null,
          status: null,
        },
        {
          id: 2,
          screen: null,
          tapNumber: null,
          status: null,
        },
        {
          id: 3,
          screen: null,
          tapNumber: null,
          status: null,
        },
        {
          id: 4,
          screen: null,
          tapNumber: null,
          status: null,
        },
      ],
    }
  },
  computed: {
    ...mapState('pages/kiosk/easychope/tapStations', ['tapStationConfiguring', 'fetching']),
  },
  methods: {
    ...mapActions('pages/kiosk/easychope/tapStations', [
      'startTapStationSetup',
      'cleanTapStationSetup',
      'setTapStation',
      'setTapStationStore',
      'testRelay',
      'saveRelayInfo',
      'finishSetup',
    ]),
    show(tapStation) {
      this.startTapStationSetup(tapStation)
      tapStation.taps.forEach(tap => {
        const relay = this.relays.find(r => r.id === tap.relayNumber)
        if (relay) {
          relay.screen = tap.screen
          relay.tapNumber = tap.number
          relay.status = tap.status
          relay.millisecondsPerPulse = tap.millisecondsPerPulse
        }
      })
      this.$bvModal.show('tapstation-config')
    },
    cancelSetup() {
      this.cleanTapStation()
      this.$bvModal.hide('tapstation-config')
    },
    cleanTapStation() {
      this.cleanTapStationSetup()
      this.$emit('closed')
    },
    async setTapStationStoreLocal() {
      try {
        await this.setTapStationStore()
      } catch (error) {
        this.showError({ error })
      }
    },
    async testRelayLocal(relayNumber) {
      try {
        await this.testRelay(relayNumber)
        this.showSuccess({
          message: this.$t('Teste enviado com sucesso, verifique qual led da chopeira foi aceso'),
        })
      } catch (error) {
        this.showError({ error })
      }
    },
    async saveRelayInfoLocal(relay) {
      try {
        await this.saveRelayInfo(relay)
        this.showSuccess({ message: this.$t('Dados salvos com sucesso') })
      } catch (error) {
        this.showError({ error })
      }
    },
    async finishSetupLocal() {
      try {
        await this.finishSetup()
        this.showSuccess({ message: this.$t('Configuração finalizada com sucesso') })
        this.$bvModal.hide('tapstation-config')
      } catch (error) {
        this.showError({ error })
      }
    },
  },
}
</script>

<style scoped>
h6 {
  text-align: center;
}
</style>
