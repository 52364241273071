<template>
  <div>
    <e-filters
      :searching="fetching"
      @search="filterData"
      @reset="resetFiltersLocal"
    >
      <div>
        <b-row>
          <b-col md="4">
            <e-store-combo
              id="filters-store"
              v-model="filters.storeId"
              required
              @input="storeChanged()"
            />
          </b-col>
          <b-col md="4">
            <FormulateInput
              id="order-paybox"
              v-model="filters.payboxId"
              type="vue-select"
              class="required"
              :label="$t('PDV')"
              :placeholder="$t('Selecione')"
              validation="required"
              :options="payBoxesOptions"
            />
          </b-col>
          <b-col md="4">
            <FormulateInput
              id="filters-status-command"
              v-model="filters.statusCommand"
              type="vue-select"
              :label="$t('Status')"
              :placeholder="$t('Selecione')"
              :options="statusCommandOptions"
            />
          </b-col>
        </b-row>
      </div>
    </e-filters>

    <b-card-actions
      class="mb-2"
      action-refresh
      :show-loading="fetching"
      @refresh="filterData"
    >
      <div slot="title">
        <e-page-size-selector
          :per-page="paging.pageSize"
          @change="pageSizeChange"
        />
      </div>
      <b-table
        show-empty
        responsive
        striped
        class="bordered"
        :empty-text="getEmptyTableMessage($tc('PAYBOX_COMMAND.NAME'))"
        :fields="fields"
        :items="commands"
        no-local-sorting
        :sort-by.sync="sorting.sortBy"
        :sort-desc.sync="sorting.sortDesc"
        @sort-changed="getData"
      />

      <b-row>
        <b-col cols="6">
          <e-pagination-summary
            :current-page="paging.currentPage"
            :per-page="paging.pageSize"
            :total="paging.rowCount"
            :total-on-page="paging.rowsInCurrentPage"
          />
        </b-col>
        <b-col cols="6">
          <b-pagination
            v-model="paging.currentPage"
            align="right"
            :total-rows="paging.rowCount"
            :per-page="paging.pageSize"
            aria-controls="table-manage-pay-box"
            @change="pageChange"
          />
        </b-col>
      </b-row>
    </b-card-actions>

    <manage-pay-box-commands-sidebar
      ref="managePayBoxCommandsSidebar"
      @after-save="getData"
    />

    <fab
      v-if="$can('Create', 'PayBoxCommand')"
      :main-tooltip="$t('Cadastrar novo Comando')"
      @click="onShowSidebar"
    />
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import { BRow, BCol, BPagination, BTable } from 'bootstrap-vue'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import { EPageSizeSelector, EPaginationSummary } from '@/views/components'
import fab from '@/views/components/FAB.vue'
import { translate } from '@/mixins'
import EFilters from '@/views/components/EFilters.vue'
import EStoreCombo from '@/views/components/inputs/EStoreCombo.vue'
import ManagePayBoxCommandsSidebar from './ManagePayBoxCommandsSidebar.vue'

export default {
  components: {
    BRow,
    BCol,
    BCardActions,
    BPagination,
    BTable,
    EPaginationSummary,
    EPageSizeSelector,
    fab,
    EFilters,
    EStoreCombo,
    ManagePayBoxCommandsSidebar
  },

  mixins: [translate],

  data() {
    return {
      fetching: false,
    }
  },

  computed: {
    ...mapGetters('pages/payboxCommands', ['payBoxesOptions', 'commandsOptions', 'statusCommandOptions']),
    ...mapState('pages/payboxCommands', {
      payBoxes: 'payBoxes',
      paging: 'paging',
      sorting: 'sorting',
      filters: 'filters',
      commands: 'commands'
    }),

    buttons() {
      return [
        {
          icon: 'journal-code',
          event: 'request-logs',
          title: this.$t('Solicitar logs'),
        },
      ]
    },

    fields() {
      return [
        {
          label: this.$t('Código'),
          key: 'id',
          thClass: 'text-center',
          tdClass: 'text-center',
          thStyle: { width: '100px' },
          sortable: true,
        },
        {
          label: this.$t('Loja'),
          key: 'payBox.store.id',
          thClass: 'text-center',
          tdClass: 'text-left',
          formatter: (val, index, item) => this.$options.filters.storeName(item.payBox.store),
          sortable: true,
          thStyle: { width: '100px' },
        },
        {
          label: this.$t('Pdv'),
          key: 'payBox.description',
          tdClass: 'text-center',
          thStyle: { width: '100px' },
          sortable: true,
        },
        {
          label: this.$t('Comando'),
          key: 'command',
          tdClass: 'text-left',
          thStyle: { width: '100px' },
        },
        {
          label: this.$t('Resposta'),
          key: 'commandResponse',
          tdClass: 'text-left',
          thStyle: { width: '100px' },
        },
        {
          label: this.$t('Usuário Logado'),
          key: 'loggedUser.name',
          tdClass: 'text-left',
          thStyle: { width: '100px' },
        },
        {
          label: this.$t('Status'),
          key: 'status',
          tdClass: 'text-center',
          thStyle: { width: '100px' },
          sortable: true,
        },
        {
          label: this.$t('Data Solicitação'),
          key: 'sendingDate',
          tdClass: 'text-center',
          thStyle: { width: '100px' },
          sortable: true,
          formatter: value => this.$options.filters.datetime(value),
        },
        {
          label: this.$t('Data Execução'),
          key: 'executedDate',
          tdClass: 'text-center',
          thStyle: { width: '100px' },
          sortable: true,
          formatter: value => this.$options.filters.datetime(value),
        },
        {
          label: this.$t('Inforamações Adicionais'),
          key: 'additionalInformation',
          tdClass: 'text-left',
          thStyle: { width: '100px' },
        },
      ]
    },
  },

  methods: {
    ...mapActions('pages/payboxCommands', [
      'fetchPayBoxes',
      'fetchCommands',
      'setCurrentPage',
      'setPageSize',
      'resetFilters',
    ]),

    async getData() {
      this.$nextTick(async () => {
        try {
          this.fetching = true
          await this.fetchCommands()
        } catch (error) {
          this.showGenericError({ error })
        } finally {
          this.fetching = false
        }
      })
    },

    storeChanged() {
      if (this.filters.storeId) {
        this.$nextTick(async () => {
          try {
            this.fetching = true
            await this.fetchPayBoxes(this.filters.storeId)
          } catch (error) {
            this.showError({ error })
          } finally {
            this.fetching = false
          }
        })
      }
    },

    resetFiltersLocal() {
      this.resetFilters()
      this.getData()
    },
    filterData() {
      this.setCurrentPage(1)
      this.getData()
    },

    pageSizeChange(pageSize) {
      this.setPageSize(pageSize)
      this.getData()
    },

    pageChange(currentPage) {
      this.setCurrentPage(currentPage)
      this.getData()
    },

    onShowSidebar() {
      this.$refs.managePayBoxCommandsSidebar.showCreate()
    },
  },
}
</script>

<style lang="scss" scoped></style>
