import { sorting } from '@/mixins'
import { storePagination } from '@/mixins/store'
import axios from '@axios'

const getInitialState = () => ({
  ...storePagination.state(),
  summaryFields: null,
  sorting: {
    sortBy: 'createdDate',
    sortDesc: true,
  },
  reportData: null,
  consumptions: [],
  filters: {
    palmBeerBarrelCode: null,
    brandId: null,
    skuId: null,
    storeId: null,
    customerId: null,
    consumptionType: null,
    consumptionRangeDate: {
      startDate: null,
      endDate: null,
    },
  },
})

export default {
  namespaced: true,
  state: {
    ...getInitialState(),
  },
  getters: {},

  mutations: {
    ...storePagination.mutations,

    SET_SUMMARY(state, summaryFields) {
      state.summaryFields = summaryFields
    },
    SET_CONSUMPTIONS(state, consumptions) {
      state.consumptions = consumptions
    },
    SET_REPORT_DATA(state, reportData) {
      state.reportData = reportData
    },
    SET_FILTERS(state, filters) {
      state.filters = filters
    },
    CLEAN_STATE(state) {
      const { filters, paging, sorting: sortingData } = getInitialState()
      state.filters = filters
      state.sorting = sortingData
      state.paging = paging
    },
  },

  actions: {
    ...storePagination.actions,

    async fetchConsumptions({ commit, state }) {
      const { data } = await axios.get(`/api/palmbeer/consumptions`, {
        params: {
          barrelId: state.filters.palmBeerBarrelCode,
          storeId: state.filters.storeId,
          skuId: state.filters.skuId,
          brandId: state.filters.brandId,
          customerId: state.filters.customerId,
          customerType: state.filters.consumptionType,
          dateFrom: state.filters.consumptionRangeDate.startDate,
          dateTo: state.filters.consumptionRangeDate.endDate,
          pageSize: state.paging.pageSize,
          pageIndex: state.paging.currentPage - 1,
          sortBy: sorting.methods.getSorting(state),
        },
      })
      commit('SET_CONSUMPTIONS', data.results)
      commit('SET_PAGING', {
        ...state.paging,
        rowCount: data.rowCount,
        rowsInCurrentPage: data.rowsInCurrentPage,
      })
      commit('SET_SUMMARY', data.summaryFields)
    },

    resetFilters({ commit }) {
      commit('SET_FILTERS', getInitialState().filters)
    },

    cleanState({ commit }) {
      commit('CLEAN_STATE')
    },
    async generateReport({ state, commit }) {
      const params = {
        palmBeerBarrelCode: state.filters.palmBeerBarrelCode,
        storeId: state.filters.storeId,
        skuId: state.filters.skuId,
        brandId: state.filters.brandId,
        customerId: state.filters.customerId,
        consumptionType: state.filters.consumptionType,
        dateFrom: state.filters.consumptionRangeDate.startDate,
        dateTo: state.filters.consumptionRangeDate.endDate,
        pageSize: 0,
        pageIndex: 0,
        sortBy: 'CreatedDate ASC',
      }

      const { data } = await axios.get('/api/palmbeer/consumptions/download', {
        params,
        responseType: 'blob',
      })
      const blob = new Blob([data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      })
      const url = window.URL.createObjectURL(blob)
      commit('SET_REPORT_DATA', url)
    },
  },
}
