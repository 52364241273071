export default [
  {
    path: `/caixas`,
    name: 'pdv-manage-pay-box',
    component: () => import('@/views/pages/pdv/manage-pay-box/ManagePayBoxList.vue'),
    meta: {
      pageTitle: 'Gerenciar Caixas',
      navActiveLink: 'pdv-manage-pay-box',
      resource: 'PayBox',
      action: 'Read',
      breadcrumb: [
        {
          text: 'PDV',
        },
        {
          text: 'Gerenciar Caixas',
          active: true,
        },
      ],
    },
  },

  {
    path: `/caixas/comandos`,
    name: 'pdv-manage-pay-box-commands',
    component: () => import('@/views/pages/pdv/manage-pay-box-commands/ManagePayBoxCommandsList.vue'),
    meta: {
      pageTitle: 'Comandos do Caixas',
      navActiveLink: 'pdv-manage-pay-box-commands',
      resource: 'PayBoxCommand',
      action: 'Read',
      breadcrumb: [
        {
          text: 'PDV',
        },
        {
          text: 'Comandos do Caixas',
          active: true,
        },
      ],
    },
  },

  {
    path: `/caixas/extrato`,
    name: 'pdv-pay-box-statements',
    component: () => import('@/views/pages/pdv/erp/pay-box-statement/PayBoxStatement.vue'),
    meta: {
      pageTitle: 'Extrato do Caixa',
      resource: 'PayBox',
      action: 'Read',
      breadcrumb: [
        {
          text: 'PDV',
        },
        {
          text: 'Extrato do Caixa',
          active: true,
        },
      ],
    },
  },

  {
    path: `/caixas/extrato/fechamento/:cashBookId`,
    name: 'pdv-pay-box-closing',
    component: () => import('@/views/pages/pdv/erp/pay-box-close/PayBoxClose.vue'),
    meta: {
      pageTitle: 'Extrato do Fechamento',
      resource: 'PayBox',
      action: 'Read',
      breadcrumb: [
        {
          text: 'PDV',
        },
        {
          text: 'Extrato do Caixa',
          to: '/caixas/extrato',
        },
        {
          text: 'Fechamento do Caixa',
          active: true,
        },
      ],
    },
  },

  // PDV Routes
  {
    path: `/pdv/caixa`,
    name: 'pdv-pay-box',
    component: () => import('@/views/pages/pdv/pay-box/PayBox.vue'),
    meta: {
      pageTitle: 'Nova Venda',
      isDynamicPageTitle: true,
      layout: 'payBox',
      resource: 'PayBoxSale',
      action: 'Create',
    },
  },
  {
    path: `/pdv/abertura`,
    name: 'pdv-pay-box-open',
    component: () => import('@/views/pages/pdv/pay-box-open/PayBoxOpen.vue'),
    meta: {
      pageTitle: 'Abertura caixa',
      layout: 'payBox',
      resource: 'CashBook',
      action: 'Open',
    },
  },
  {
    path: `/pdv/fechamento`,
    name: 'pdv-pay-box-close',
    component: () => import('@/views/pages/pdv/pay-box-close/PayBoxClose.vue'),
    meta: {
      pageTitle: 'Fechamento caixa',
      layout: 'full',
      resource: 'CashBook',
      action: 'Close',
    },
  },
  {
    path: `/pdv/gerenciar-vendas`,
    name: 'pdv-pay-box-manage-sales',
    component: () => import('@/views/pages/pdv/pay-box-manage-sale/PayBoxManageSale.vue'),
    meta: {
      pageTitle: 'Gerenciar vendas',
      layout: 'payBox',
      // resource: 'Client',
      // action: 'Read',
    },
  },
  {
    path: `/pdv/vendas-de-delivery`,
    name: 'pdv-pay-box-delivery-sales',
    component: () => import('@/views/pages/pdv/pay-box-manage-sale/PayBoxManageSale.vue'),
    meta: {
      pageTitle: 'Vendas pendentes',
      layout: 'payBox',
      // resource: 'Client',
      // action: 'Read',
    },
  },
  {
    path: `/pdv/retorno-de-produtos`,
    name: 'pdv-pay-box-reclaim-products',
    component: () => import('@/views/pages/pdv/pay-box-reclaim-products/PayBoxReclaimProducts.vue'),
    meta: {
      pageTitle: 'Retorno de produtos',
      layout: 'payBox',
      // resource: 'Client',
      // action: 'Read',
    },
  },
  {
    path: `/pdv/extrato`,
    name: 'pdv-pay-box-statement',
    component: () => import('@/views/pages/pdv/pay-box-statement/PayBoxStatement.vue'),
    meta: {
      pageTitle: 'Extrato do caixa',
      layout: 'payBox',
      // resource: 'Client',
      // action: 'Read',
    },
  },
  {
    path: `/pdv/contas-a-pagar`,
    name: 'pdv-pay-box-accounts-payable',
    component: () =>
      import('@/views/pages/pdv/pay-box-accounts-payable/PayBoxAccountsPayableList.vue'),
    meta: {
      pageTitle: 'Despesas',
      layout: 'payBox',
      // resource: 'Client',
      // action: 'Read',
    },
  },
  // Bar: tab management
  {
    path: `/pdv/gerenciar-comandas`,
    name: 'pdv-pay-box-bar-tab-management',
    component: () => import('@/views/pages/pdv/pay-box-bar/BarTabList.vue'),
    meta: {
      pageTitle: 'Gerenciar Comandas',
      layout: 'payBox',
      resource: 'BarTabConsumption',
      action: 'Read',
    },
  },
  // Bar: consumption history
  {
    path: `/pdv/bar/historico-consumo`,
    name: 'pdv-pay-box-bar-tab-consumption-history',
    component: () => import('@/views/pages/pdv/pay-box-bar/BarTabConsumptionList.vue'),
    meta: {
      pageTitle: 'Histórico de consumos',
      layout: 'payBox',
      resource: 'BarTabConsumption',
      action: 'ReadHistory',
    },
  },
  {
    path: `/pdv/configuration`,
    name: 'pdv-pay-box-configuration',
    component: () => import('@/views/pages/pdv/pay-box-configuration/PayBoxConfiguration.vue'),
    meta: {
      pageTitle: 'Configuração do PDV',
      layout: 'payBox',
      resource: 'PayBox',
      action: 'Setup',
    },
  },
  {
    path: `/pdv/inactive`,
    name: 'pdv-pay-box-inactive',
    component: () => import('@/views/pages/pdv/pay-box-configuration/PayBoxInactive.vue'),
    meta: {
      layout: 'full',
    },
  },
  {
    path: `/pdv/unknown-error`,
    name: 'pdv-pay-box-unknown-error',
    component: () => import('@/views/pages/pdv/pay-box-configuration/PayBoxUnknownError.vue'),
    meta: {
      layout: 'full',
    },
  },
]
