import tapStations from "./tap-stations"

export default {
  namespaced: true,
  modules: {
    tapStations,
  },
  getters: {
  },
  state: {
  },
  actions: {
  },
  mutations: {
  },
}
