<template>
  <div>
    <FormulateForm>
      <FormulateInput
        v-model="localFiles"
        type="group"
        label=" "
        :repeatable="!isReadOnly"
        :add-label="$t('Adicionar mídia')"
        remove-position="before"
      >
        <template #default="{ index }">
          <b-row>
            <b-col md="3">
              <FormulateInput
                :id="`files_form-tap-status-${index}`"
                name="beerTapStatus"
                type="vue-select"
                :label="$t('Status da torneira')"
                :options="beerTapStatus"
                :disabled="isReadOnly"
                :clearable="!isReadOnly"
              />
            </b-col>
            <b-col md="9">
              <FormulateInput
                :id="`files_form-file-${index}`"
                name="file"
                type="uploader"
                max-files="1"
                deletable="false"
                :linkable="true"
                :readonly="fileIsReadOnly(index)"
                theme="list"
                :label="$t('')"
              />
            </b-col>
          </b-row>
        </template>

        <template #remove="{ index: indexItem, removeItem }">
          <div
            v-if="!isReadOnly"
            class="mb-1 position-absolute"
            style="right: 7px; top: 25%"
          >
            <e-button
              :title="$t('Deletar arquivo')"
              icon="trash"
              variant="danger"
              size="sm"
              class="px-0"
              @click="() => onRemoveDocument(indexItem, removeItem)"
            />
          </div>
        </template>
      </FormulateInput>
    </FormulateForm>
  </div>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'
import EButton from '@/views/components/EButton.vue'

export default {
  components: {
    BRow,
    BCol,
    EButton,
  },

  props: {
    files: {
      type: Array,
      required: true,
    },
    isReadOnly: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {}
  },

  computed: {
    localFiles: {
      get() {
        return this.files
      },
      set(val) {
        this.$emit('set-files', val)
      },
    },
    beerTapStatus() {
      return [
        { label: this.$t('Não configurada'), value: 'NotConfigured' },
        { label: this.$t('Configurando'), value: 'Configuring' },
        { label: this.$t('Aguardando leitura do cartão'), value: 'PendingReadDevice' },
        { label: this.$t('Configurada'), value: 'Configured' },
        { label: this.$t('Pronta'), value: 'Ready' },
        { label: this.$t('Inativa'), value: 'Inactive' },
        { label: this.$t('Aberta'), value: 'Opened' },
        { label: this.$t('Servindo'), value: 'Serving' },
        { label: this.$t('Retirada finalizada'), value: 'ServeCompleted' },
      ]
    },
  },

  methods: {
    onRemoveDocument(index, removeCallback) {
      this.$emit('remove-file', index)
      removeCallback()
    },

    fileIsReadOnly(index) {
      if (this.isReadOnly) return true
      return !!this.files[index]?.id
    },
  },
}
</script>

<style lang="sass" scoped></style>
