import { sorting } from '@/mixins'
import { storePagination } from '@/mixins/store'
import axios from '@axios'

const getInitialState = () => ({
  ...storePagination.state(),
  tapStationConfiguring: null,
  fetching: false,
  storeId: null,
  tapStations: [],
  barrels: [],
  filters: {
    storeId: null,
    mcAddress: null,
    status: null,
  },
})

export default {
  namespaced: true,
  state: {
    ...getInitialState(),
  },
  getters: {},

  mutations: {
    ...storePagination.mutations,

    SET_TAP_STATIONS(state, tapStations) {
      state.tapStations = tapStations
    },
    SET_BARRELS(state, barrels) {
      state.barrels = barrels
    },
    SET_FILTERS(state, filters) {
      state.filters = filters
    },
    CLEAN_STATE(state) {
      const { filters, paging, sorting: sortingData } = getInitialState()
      state.filters = filters
      state.sorting = sortingData
      state.paging = paging
    },
    SET_FETCHING(state, fetching) {
      state.fetching = fetching
    },
    SET_TAP_STATION_CONFIGURING(state, tapStation) {
      state.tapStationConfiguring = tapStation
    },
  },

  actions: {
    ...storePagination.actions,

    async fetchTapStations({ commit, state }) {
      commit('SET_FETCHING', true)
      const response = await axios.get('/api/easychope/tap-stations', {
        params: {
          storeId: state.filters.storeId,
          tapStationId: state.filters.mcAddress,
          tapStationStatus: state.filters.status,
        },
      })
      commit('SET_FETCHING', false)
      commit('SET_TAP_STATIONS', response.data.results)
    },
    startTapStationSetup({ commit }, tapStation) {
      commit('SET_TAP_STATION_CONFIGURING', tapStation)
    },
    cleanTapStationSetup({ commit }) {
      commit('SET_TAP_STATION_CONFIGURING', null)
    },
    async setTapStationStore({ commit, state }) {
      commit('SET_FETCHING', true)
      try {
        if (state.tapStationConfiguring.storeId) {
          await axios.patch(
            `/api/easychope/tap-stations/${state.tapStationConfiguring.id}/set-store/${state.tapStationConfiguring.storeId}`
          )
        } else {
          throw Error('Nenhuma loja selecionada')
        }
      } finally {
        commit('SET_FETCHING', false)
      }
    },
    async testRelay({ commit, state }, relayNumber) {
      commit('SET_FETCHING', true)
      try {
        await axios.patch(
          `/api/easychope/tap-stations/${state.tapStationConfiguring.id}/test-relay/${relayNumber}`
        )
      } finally {
        commit('SET_FETCHING', false)
      }
    },
    async restartTapStation({ commit }, { tapStation, service }) {
      commit('SET_FETCHING', true)
      try {
        await axios.patch(`/api/easychope/tap-stations/${tapStation.id}/restart/${service}`)
      } finally {
        commit('SET_FETCHING', false)
      }
    },
    async saveRelayInfo({ commit, state }, relay) {
      commit('SET_FETCHING', true)
      try {
        await axios.patch(
          `/api/easychope/tap-stations/${state.tapStationConfiguring.id}/set-info-by-relay/${relay.relayNumber}`,
          relay
        )
      } finally {
        commit('SET_FETCHING', false)
      }
    },
    async finishSetup({ commit, state }) {
      commit('SET_FETCHING', true)
      try {
        await axios.patch(
          `/api/easychope/tap-stations/${state.tapStationConfiguring.id}/finish-setup`
        )
        commit('SET_TAP_STATION_CONFIGURING', null)
      } finally {
        commit('SET_FETCHING', false)
      }
    },
    async fetchBarrels({ commit, state }) {
      if (!state.filters.storeId) {
        commit('SET_BARRELS', [])
        return
      }
      const { data } = await axios.get(`/api/easychope/barrels`, {
        params: {
          storeId: state.filters.storeId,
          brandId: state.filters.brandId,
          remainingLiquid: 2000,
          pageSize: state.paging.pageSize,
          pageIndex: state.paging.currentPage - 1,
          sortBy: sorting.methods.getSorting(state),
        },
      })
      commit('SET_BARRELS', data.results)
      commit('SET_PAGING', {
        ...state.paging,
        rowCount: data.rowCount,
        rowsInCurrentPage: data.rowsInCurrentPage,
      })
    },

    setStoreFilter({ commit, state }, storeId) {
      commit('SET_FILTERS', { ...state.filters, storeId })
    },

    resetFilters({ commit }) {
      commit('SET_FILTERS', getInitialState().filters)
    },

    cleanState({ commit }) {
      commit('CLEAN_STATE')
    },
  },
}
