var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "e-filters",
        {
          attrs: { searching: _vm.fetching },
          on: { search: _vm.getData, reset: _vm.resetFiltersLocal },
        },
        [
          _c(
            "b-row",
            [
              _c(
                "b-col",
                { attrs: { md: "4" } },
                [
                  _c("e-store-combo", {
                    attrs: {
                      id: "storeId",
                      required: false,
                      filter: function (store) {
                        return store.type === "Kiosk"
                      },
                    },
                    model: {
                      value: _vm.filters.storeId,
                      callback: function ($$v) {
                        _vm.$set(_vm.filters, "storeId", $$v)
                      },
                      expression: "filters.storeId",
                    },
                  }),
                ],
                1
              ),
              _c(
                "b-col",
                { attrs: { md: "2" } },
                [
                  _c("FormulateInput", {
                    attrs: {
                      id: "mcAddress",
                      label: _vm.$t("McAddress"),
                      placeholder: "00:00:00:00:00",
                    },
                    model: {
                      value: _vm.filters.mcAddress,
                      callback: function ($$v) {
                        _vm.$set(_vm.filters, "mcAddress", $$v)
                      },
                      expression: "filters.mcAddress",
                    },
                  }),
                ],
                1
              ),
              _c(
                "b-col",
                { attrs: { md: "3" } },
                [
                  _c("FormulateInput", {
                    attrs: {
                      id: "status",
                      type: "vue-select",
                      label: _vm.$t("Status"),
                      placeholder: _vm.$t("Todos"),
                      options: [
                        {
                          value: "NotConfigured",
                          label: _vm.$t("Não configurada"),
                        },
                        { value: "Configuring", label: _vm.$t("Configurando") },
                        { value: "Configured", label: _vm.$t("Configurado") },
                      ],
                    },
                    model: {
                      value: _vm.filters.status,
                      callback: function ($$v) {
                        _vm.$set(_vm.filters, "status", $$v)
                      },
                      expression: "filters.status",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "b-row",
        { staticClass: "match-height" },
        _vm._l(_vm.tapStations, function (tapStation, index) {
          return _c(
            "b-col",
            { key: tapStation.id + "_" + index, attrs: { md: "6", lg: "3" } },
            [
              _c(
                "b-card-actions",
                {
                  attrs: {
                    "no-actions": "",
                    "show-loading": _vm.fetching || _vm.saving,
                    title: tapStation.id,
                    "custom-actions": [
                      {
                        event: "setup",
                        icon: "Settings",
                        title: _vm.$t("Configurar chopeira"),
                      },
                      {
                        event: "restart-service",
                        icon: "RefreshCw",
                        title: _vm.$t("Reiniciar serviço"),
                      },
                      {
                        event: "restart-local-database",
                        icon: "Database",
                        title: _vm.$t("Reiniciar base local"),
                      },
                    ],
                  },
                  on: {
                    setup: function ($event) {
                      return _vm.openTapStationSetup(tapStation, index)
                    },
                    "restart-service": function ($event) {
                      return _vm.restartTapStationLocal(tapStation, "easychope")
                    },
                    "restart-local-database": function ($event) {
                      return _vm.restartTapStationLocal(
                        tapStation,
                        "easychope-ld"
                      )
                    },
                  },
                },
                [
                  tapStation.storeId
                    ? _c("p", [
                        _vm._v(
                          " " +
                            _vm._s(_vm.$t("Loja")) +
                            ": " +
                            _vm._s(
                              _vm._f("storeName")(
                                _vm.storeById(tapStation.storeId)
                              )
                            ) +
                            " "
                        ),
                      ])
                    : _vm._e(),
                  _c(
                    "h3",
                    [
                      _c("e-status-badge", {
                        attrs: {
                          status: tapStation.status,
                          options: _vm.statuses,
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "b-row",
                    { staticClass: "mt-1" },
                    _vm._l(tapStation.taps, function (tap) {
                      return _c(
                        "b-col",
                        { key: "tap_" + tap.id, attrs: { md: "12" } },
                        [
                          _c(
                            "b-card",
                            {
                              staticClass: "mb-1",
                              staticStyle: {
                                border: "1px solid gray",
                                "background-color": "#eaeaea",
                              },
                              attrs: {
                                title: _vm.$t("Torneira") + " " + tap.number,
                              },
                            },
                            [
                              _c(
                                "b-row",
                                [
                                  _c(
                                    "b-col",
                                    { attrs: { md: "6" } },
                                    [
                                      _c("FormulateInput", {
                                        attrs: {
                                          type: "label",
                                          label: _vm.$t("Posição"),
                                          value: tap.screen,
                                          options: [
                                            {
                                              value: "Left",
                                              label: _vm.$t("Esquerda"),
                                            },
                                            {
                                              value: "Right",
                                              label: _vm.$t("Direita"),
                                            },
                                            {
                                              value: "Full",
                                              label: _vm.$t("Inteira"),
                                            },
                                          ],
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-col",
                                    { attrs: { md: "6" } },
                                    [
                                      _c("FormulateInput", {
                                        attrs: {
                                          type: "label",
                                          precision: 5,
                                          label: _vm.$t("Fator"),
                                        },
                                        model: {
                                          value: tap.millisecondsPerPulse,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              tap,
                                              "millisecondsPerPulse",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "tap.millisecondsPerPulse",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c("e-status-badge", {
                                attrs: {
                                  status: tap.status,
                                  options: _vm.statuses,
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    }),
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        }),
        1
      ),
      _c("tap-station-setup", {
        ref: "modalTapStationSetup",
        on: { closed: _vm.getData },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }