<template>
  <div>
    <e-filters
      :searching="fetching"
      @search="getData"
      @reset="resetFiltersLocal"
    >
      <b-row>
        <b-col md="4">
          <e-store-combo
            id="storeId"
            v-model="filters.storeId"
            :required="false"
            :filter="(store) => store.type === 'Kiosk'"
          />
        </b-col>
        <b-col md="2">
          <FormulateInput
            id="mcAddress"
            v-model="filters.mcAddress"
            :label="$t('McAddress')"
            placeholder="00:00:00:00:00"
          />
        </b-col>
        <b-col md="3">
          <FormulateInput
            id="status"
            v-model="filters.status"
            type="vue-select"
            :label="$t('Status')"
            :placeholder="$t('Todos')"
            :options="[
              { value: 'NotConfigured', label: $t('Não configurada') },
              { value: 'Configuring', label: $t('Configurando') },
              { value: 'Configured', label: $t('Configurado') },
            ]"
          />
        </b-col>
      </b-row>
    </e-filters>
    <b-row class="match-height">
      <b-col
        v-for="(tapStation, index) in tapStations"
        :key="`${tapStation.id}_${index}`"
        md="6"
        lg="3"
      >
        <b-card-actions
          no-actions
          :show-loading="fetching || saving"
          :title="tapStation.id"
          :custom-actions="[
            { event: 'setup', icon: 'Settings', title: $t('Configurar chopeira') },
            { event: 'restart-service', icon: 'RefreshCw', title: $t('Reiniciar serviço') },
            {
              event: 'restart-local-database',
              icon: 'Database',
              title: $t('Reiniciar base local'),
            },
          ]"
          @setup="openTapStationSetup(tapStation, index)"
          @restart-service="restartTapStationLocal(tapStation, 'easychope')"
          @restart-local-database="restartTapStationLocal(tapStation, 'easychope-ld')"
        >
          <p v-if="tapStation.storeId">
            {{ $t('Loja') }}: {{ storeById(tapStation.storeId) | storeName }}
          </p>
          <h3>
            <e-status-badge
              :status="tapStation.status"
              :options="statuses"
            />
          </h3>
          <b-row class="mt-1">
            <b-col
              v-for="tap in tapStation.taps"
              :key="`tap_${tap.id}`"
              md="12"
            >
              <b-card
                :title="`${$t('Torneira')} ${tap.number}`"
                class="mb-1"
                style="border: 1px solid gray; background-color: #eaeaea"
              >
                <b-row>
                  <b-col md="6">
                    <FormulateInput
                      type="label"
                      :label="$t('Posição')"
                      :value="tap.screen"
                      :options="[
                        { value: 'Left', label: $t('Esquerda') },
                        { value: 'Right', label: $t('Direita') },
                        { value: 'Full', label: $t('Inteira') },
                      ]"
                    />
                  </b-col>
                  <b-col md="6">
                    <FormulateInput
                      v-model="tap.millisecondsPerPulse"
                      type="label"
                      :precision="5"
                      :label="$t('Fator')"
                    />
                  </b-col>
                </b-row>
                <e-status-badge
                  :status="tap.status"
                  :options="statuses"
                />
              </b-card>
            </b-col>
          </b-row>
        </b-card-actions>
      </b-col>
    </b-row>
    <tap-station-setup
      ref="modalTapStationSetup"
      @closed="getData"
    />
  </div>
</template>

<script>
import { BCard, BRow, BCol } from 'bootstrap-vue'
import { mapActions, mapGetters, mapState } from 'vuex'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import { EFilters, EStoreCombo, EStatusBadge } from '@/views/components'
import TapStationSetup from './components/TapStationSetup.vue'

export default {
  components: {
    BCard,
    BCardActions,
    BRow,
    BCol,
    EFilters,
    EStoreCombo,
    EStatusBadge,
    TapStationSetup,
  },

  data() {
    return {
      showSidebar: false,
      edit: false,
      saving: false,
      selectedBeerTap: null,
      localBeerTaps: [
        { editingPrice: false },
        { editingPrice: false },
        { editingPrice: false },
        { editingPrice: false },
      ],
      // eslint-disable-next-line global-require
      noImage: require('@/assets/images/noimage.png'),
    }
  },

  computed: {
    ...mapState('pages/kiosk', ['stores']),
    ...mapState('pages/kiosk/easychope/tapStations', ['tapStations', 'filters', 'fetching']),
    ...mapGetters('pages/kiosk', ['storeOptions']),
    ...mapGetters('app', ['storeById']),
    selectedStore() {
      return this.filters.storeId
    },
    barrelImage() {
      return beerTap => beerTap.barrelInstalled?.sku?.product?.imageSquare
    },
    barrelCapacity() {
      return beerTap => (beerTap.barrelInstalled?.capacity || 0) / 1000
    },
    barrelName() {
      return beerTap =>
        beerTap.barrelInstalled?.sku?.name
          ? `${beerTap.barrelInstalled?.sku?.name} (${this.$t('Barril')} #${
              beerTap.barrelInstalled.palmBeerBarrelCode
            })`
          : this.$t('Não instalado')
    },
    statuses() {
      return [
        {
          value: 'NotConfigured',
          label: this.$t('Não Configurada'),
          classStyle: 'danger',
        },
        {
          value: 'Configuring',
          label: this.$t('Configurando'),
          classStyle: 'warning',
        },
        {
          value: 'Configured',
          label: this.$t('Configurada'),
          classStyle: 'success',
        },
        {
          value: 'PendingReadDevice',
          label: this.$t('Aguardando leitura do cartão'),
          classStyle: 'warning',
        },
        {
          value: 'Inactive',
          label: this.$t('Inativa'),
          classStyle: 'danger',
        },
      ]
    },
  },

  watch: {},

  async mounted() {
    await this.fetchStores()
    if (this.stores.length === 1) {
      this.setStoreFilter(this.stores[0].id)
    }
  },

  methods: {
    ...mapActions('pages/kiosk/easychope/tapStations', [
      'fetchTapStations',
      'setStoreFilter',
      'restartTapStation',
      'resetFilters',
    ]),
    ...mapActions('pages/kiosk', ['fetchStores']),

    async getData() {
      try {
        await this.fetchTapStations()
      } catch (error) {
        this.showError({ error })
      }
    },

    openTapStationSetup(tapStation) {
      this.$refs.modalTapStationSetup.show(tapStation)
    },

    async restartTapStationLocal(tapStation, service) {
      try {
        await this.restartTapStation({ tapStation, service })
        this.showSuccess({ message: this.$t('Reinicialização solicitada com sucesso') })
      } catch (error) {
        this.showError({ error })
      }
    },

    resetFiltersLocal() {
      this.resetFilters()
      this.getData()
    },
  },
}
</script>

<style>
img.card-img-top {
  height: 300px;
}
</style>
