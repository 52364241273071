var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-modal",
    {
      attrs: {
        id: "tapstation-config",
        title: _vm.$t("Configuração da Chopeira"),
        size: "lg",
      },
      on: { hidden: _vm.cleanTapStation },
      scopedSlots: _vm._u([
        {
          key: "modal-footer",
          fn: function () {
            return [
              _c(
                "b-row",
                {
                  staticClass:
                    "w-100 d-flex justify-content-between align-items-center",
                },
                [
                  _c(
                    "b-col",
                    { staticClass: "d-flex justify-content-end" },
                    [
                      _c("e-button", {
                        staticClass: "mr-1",
                        attrs: {
                          icon: "x",
                          variant: "outline-danger",
                          "font-scale": "1",
                          text: _vm.$t("Cancelar"),
                          busy: _vm.fetching,
                        },
                        on: {
                          click: function ($event) {
                            return _vm.cancelSetup()
                          },
                        },
                      }),
                      _c("e-button", {
                        staticClass: "cursor-pointer",
                        attrs: {
                          icon: "check",
                          variant: "primary",
                          "font-scale": "1",
                          text: _vm.$t("Finalizar configuração"),
                          busy: _vm.fetching,
                        },
                        on: {
                          click: function ($event) {
                            return _vm.finishSetupLocal()
                          },
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _vm.tapStationConfiguring
        ? _c(
            "div",
            [
              _c("h5", [
                _vm._v("Station ID: " + _vm._s(_vm.tapStationConfiguring.id)),
              ]),
              _c("e-store-combo", {
                attrs: {
                  id: "storeId",
                  required: true,
                  filter: function (store) {
                    return store.type === "Kiosk"
                  },
                },
                on: { input: _vm.setTapStationStoreLocal },
                model: {
                  value: _vm.tapStationConfiguring.storeId,
                  callback: function ($$v) {
                    _vm.$set(_vm.tapStationConfiguring, "storeId", $$v)
                  },
                  expression: "tapStationConfiguring.storeId",
                },
              }),
              _c(
                "b-row",
                _vm._l(_vm.relays, function (relay) {
                  return _c(
                    "b-col",
                    { key: relay.id, attrs: { lg: "6" } },
                    [
                      _c(
                        "FormulateForm",
                        {
                          ref: "tapForm_" + relay.id,
                          refInFor: true,
                          attrs: { "form-name": "tapForm_" + relay.id },
                          on: {
                            submit: function ($event) {
                              return _vm.saveRelayInfoLocal(
                                Object.assign({}, relay, {
                                  relayNumber: relay.id,
                                })
                              )
                            },
                          },
                        },
                        [
                          _c(
                            "b-card",
                            {
                              attrs: { title: _vm.$t("Relé") + " " + relay.id },
                            },
                            [
                              _c("FormulateInput", {
                                staticClass: "required",
                                attrs: {
                                  id: "screen_" + relay.id,
                                  type: "radio",
                                  name: "screen_" + relay.id,
                                  label: _vm.$t("Posição da torneira"),
                                  placeholder: _vm.$t("Selecione"),
                                  validation: "required",
                                  "element-class": ["d-flex", "mt-1"],
                                  options: [
                                    {
                                      value: "Left",
                                      label: _vm.$t("Esquerda"),
                                    },
                                    {
                                      value: "Right",
                                      label: _vm.$t("Direita"),
                                    },
                                    { value: "Full", label: _vm.$t("Inteira") },
                                  ],
                                },
                                model: {
                                  value: relay.screen,
                                  callback: function ($$v) {
                                    _vm.$set(relay, "screen", $$v)
                                  },
                                  expression: "relay.screen",
                                },
                              }),
                              _c("FormulateInput", {
                                staticClass: "required",
                                attrs: {
                                  id: "tapNumber_" + relay.id,
                                  type: "radio",
                                  label: _vm.$t("Número da torneira"),
                                  name: "tapNumber_" + relay.id,
                                  placeholder: _vm.$t("Selecione"),
                                  validation: "required",
                                  "element-class": ["d-flex", "mt-1"],
                                  options: [
                                    { value: "1", label: _vm.$t("1") },
                                    { value: "2", label: _vm.$t("2") },
                                    { value: "3", label: _vm.$t("3") },
                                    { value: "4", label: _vm.$t("4") },
                                  ],
                                },
                                model: {
                                  value: relay.tapNumber,
                                  callback: function ($$v) {
                                    _vm.$set(relay, "tapNumber", $$v)
                                  },
                                  expression: "relay.tapNumber",
                                },
                              }),
                              _c("FormulateInput", {
                                staticClass: "required",
                                attrs: {
                                  id: "millilitersPerPulse_" + relay.id,
                                  type: "text-number",
                                  precision: 5,
                                  label: _vm.$t("Fator"),
                                  placeholder: _vm.$t("Fator"),
                                  validation: "required",
                                },
                                on: { input: _vm.handleInput },
                                model: {
                                  value: relay.millisecondsPerPulse,
                                  callback: function ($$v) {
                                    _vm.$set(relay, "millisecondsPerPulse", $$v)
                                  },
                                  expression: "relay.millisecondsPerPulse",
                                },
                              }),
                              _c(
                                "b-row",
                                [
                                  _c(
                                    "b-col",
                                    { attrs: { md: "6" } },
                                    [
                                      _c("e-button", {
                                        staticClass: "cursor-pointer mt-1",
                                        attrs: {
                                          icon: "check",
                                          block: "",
                                          variant: "primary",
                                          "font-scale": "1",
                                          text: _vm.$t("Testar"),
                                          busy: _vm.fetching,
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.testRelayLocal(relay.id)
                                          },
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-col",
                                    { attrs: { md: "6" } },
                                    [
                                      _c("e-button", {
                                        staticClass: "cursor-pointer mt-1",
                                        attrs: {
                                          icon: "save",
                                          block: "",
                                          variant: "primary",
                                          type: "submit",
                                          "font-scale": "1",
                                          text: _vm.$t("Salvar"),
                                          busy: _vm.fetching,
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                }),
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }