<template>
  <main class="atm-container">
    <header
      v-if="!isFullScreen"
      class="header px-1"
    >
      <div class="d-flex column align-items-center justify-content-between">
        <div class="d-flex column align-items-center">
          <span class="brand-logo mr-1">
            <b-img
              id="logo"
              :src="style ? style.reducedHeaderImage : ''"
              alt="logo"
              fluid
              class="rounded"
            />
          </span>
          <h1 class="text-dark h2 mb-0">
            {{ getPageTitle }}
          </h1>
        </div>

        <div
          v-if="isDevelopment"
          class="d-flex column align-items-center"
        >
          <span>DEV (use-paybox-id)&nbsp;</span>

          <e-paybox-combo
            id="filter-payboxes"
            style="min-width: 350px"
            :value="currentPayboxConfiguration.id ? currentPayboxConfiguration.id.toString() : null"
            :required="false"
            :show-label="false"
            :placeholder="$t('Config Mac Address')"
            @input="onUsePaybox"
          />
        </div>

        <b-navbar-nav class="pr-1">
          <chat-tool class="chat-tool-nav-paybox" />
          <user-dropdown />
        </b-navbar-nav>
      </div>

      <div>
        <atm-menus :hidden="isFullScreen" />
      </div>
    </header>

    <div
      class="content-atm"
      :class="{ 'header-hide': isFullScreen }"
    >
      <router-view />
    </div>

    <aside
      id="atm_options"
      class="aside"
      :class="{ 'aside-show': !hideAside }"
      @click="hideAside = false"
      @mouseenter="onClearAsideTimer()"
      @mouseleave="onHideAside(true)"
    >
      <pay-box-aside
        :hide-aside="hideAside"
        @show-sidebar="onShowSidebar"
      />
    </aside>

    <pay-box-sidebar ref="sidebarHelper" />

    <modal-tef-messages />
  </main>
</template>

<script>
import { BNavbarNav, BImg } from 'bootstrap-vue'
import { mapActions, mapGetters, mapState } from 'vuex'
import PayBoxSidebar from '@/views/pages/pdv/pay-box/components/PayBoxSidebar.vue'
import PayBoxAside from '@/views/pages/pdv/pay-box/components/PayBoxAside.vue'
import UserDropdown from '@/layouts/pay-box/components/UserDropdown.vue'
import {
  app,
  loadingOverlay,
  payboxConfiguration,
  pdvBrowserNotAllowedConfiguration,
  scanBarcode,
  payboxCommands
} from '@/mixins'

import ModalTefMessages from '@/views/pages/pdv/pay-box/components/modals/ModalTefMessages.vue'
import ChatTool from '@/layouts/components/ChatTool.vue'
import { EPayboxCombo } from '@/views/components'
import AtmMenus from '@/views/pages/pdv/atm/AtmMenus.vue'
import { debounce } from 'lodash'
import isElectron from 'is-electron'

const environment = window.env || 'local'

export default {
  components: {
    PayBoxSidebar,
    PayBoxAside,
    UserDropdown,
    ModalTefMessages,
    BNavbarNav,
    ChatTool,
    BImg,
    EPayboxCombo,
    AtmMenus,
  },

  mixins: [
    app,
    loadingOverlay,
    pdvBrowserNotAllowedConfiguration,
    payboxConfiguration, // necessário ter por causa das ações no mounted dele
    scanBarcode,
    payboxCommands
  ],

  data() {
    return {
      hideAside: true,
      asideTimer: null,
      isDevelopment: environment !== 'production',
      isFullScreen: false
    }
  },

  computed: {
    ...mapState('pages/pdv/payBox', ['choppGiftCardData']),
    ...mapGetters('pages/pdv', ['needCashUp']),
    ...mapGetters('pages/pdv/payBox', [
      'isOrder',
      'isChoppGiftCardConversion',
      'isBarConsumption',
      'isConsignedSalePayment',
      'isConsignedSaleFinish',
      'getSaleData',
    ]),
    ...mapGetters('pages/pdv/payBoxConfiguration', ['currentPayboxConfiguration']),

    getPageTitle() {
      const { pageTitle, isDynamicPageTitle } = this.$route.meta
      const { id, barTabConsumption } = this.getSaleData
      if (isDynamicPageTitle) {
        if (this.isConsignedSaleFinish) return `Venda #${id || ''} - Finalizar Consignado`
        if (this.isConsignedSalePayment) return `Venda #${id || ''} - Registrar Pagamento`
        if (this.isOrder) return `Venda #${id || ''} - Finalizar Pedido`
        if (this.isBarConsumption) return `Fechar Comanda #${barTabConsumption.barTabNumber || ''}`
        if (this.isChoppGiftCardConversion)
          return `Converter Cartão de Presente #${this.choppGiftCardData.number || ''}`
      }

      return pageTitle || 'PDV'
    },
  },

  watch: {
    async $route() {
      await this.pageLoadPdv(true)
      // this.onScanAttachDefault()
    },
  },

  beforeMount() {
    this.mxRedirectWhenMount = true
  },

  async mounted() {
    if (isElectron()) {
      window.electronAPI.tef.displayMessage(async result => {
        await this.stTefHandleMessage(result)
      })
    }

    window.addEventListener('resize', this.getDimensions)
    this.getDimensions()

    // this.onScanAttachDefault()
  },

  destroyed() {
    window.removeEventListener('resize', this.getDimensions)
  },

  methods: {
    ...mapActions('pages/pdv/payBoxTef', {
      stTefHandleMessage: 'handleMessage',
    }),
    // eslint-disable-next-line func-names
    getDimensions: debounce(function () {
      this.isFullScreen = window.outerHeight === window.screen.availHeight
    }, 300),

    async onUsePaybox(value) {
      await new Promise(resolve => setTimeout(() => resolve()), 300)
      const valueNumber = Number(value)

      localStorage.removeItem('devOnlyUsePayboxId')
      if (valueNumber) {
        localStorage.setItem('devOnlyUsePayboxId', value)
      }

      if (!valueNumber || valueNumber === Number(this.currentPayboxConfiguration.id)) {
        return
      }

      await this.$router.push({ name: 'home' }) // evicting "NavigationDuplicated: Avoided redundant navigation to current location: /pdv/caixa"
      await this.mxPdvRouteRedirect()
    },

    onClearAsideTimer() {
      clearTimeout(this.asideTimer)
    },

    onHideAside(isLeave) {
      this.onClearAsideTimer()
      this.asideTimer = setTimeout(() => {
        this.hideAside = isLeave
      }, 400)
    },

    async onShowSidebar(type) {
      this.$refs.sidebarHelper.onShowSidebar(type)
    },
  },
}
</script>

<style lang="scss" scoped>
$col1: 25px;
$col2: 320px;

.navbar-nav {
  flex-direction: row;
}

.atm-container {
  height: 100vh;
  display: grid;
  grid-template-columns: $col1 $col2 1fr;
  grid-template-rows: 180px 1fr;

  .aside {
    display: none;
    grid-area: 2 / 1 / 3 / 3;
    background: var(--dark);
    z-index: 10;
    transform: translateX(-$col2);
    transition: ease 0s;
    transition-duration: 0.5s;
    cursor: pointer;
  }

  .aside-show {
    transform: translateX(0);
    cursor: default;
    // transition-delay: 0.5s;
  }

  .header {
    grid-area: 1 / 1 / 2 / 5;
    background: var(--light);
    border-bottom: 1px solid var(--theme-color);
  }

  .content-atm {
    grid-area: 2 / 1 / 3 / 5;
    overflow-y: auto;

    &.header-hide {
      grid-area: 1 / 1 / 3 / 5;
    }
  }
}

.brand-logo {
  box-sizing: border-box;
  width: 35px;
}

.chat-tool-nav-paybox {
  padding-top: 0.5rem;
}
</style>
