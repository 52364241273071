<template>
  <section>
    <e-filters
      :searching="fetching"
      @search="filterData"
      @reset="resetFiltersLocal"
    >
      <b-row>
        <b-col md="4">
          <e-store-combo
            id="store-id"
            v-model="filters.storeId"
            :required="true"
            :filter="(store) => store.type === 'Kiosk'"
          />
        </b-col>
        <b-col md="2">
          <FormulateInput
            id="id"
            v-model="filters.palmBeerBarrelCode"
            type="number"
            :label="$t('Id')"
            :placeholder="$t('Id')"
          />
        </b-col>
        <b-col md="2">
          <FormulateInput
            id="invoice_number"
            v-model="filters.invoiceNumber"
            type="number"
            :label="$t('Número da nota')"
          />
        </b-col>
        <b-col md="4">
          <FormulateInput
            id="brand"
            v-model="filters.brandId"
            type="vue-select"
            :label="$t('Marca')"
            :placeholder="$t('Selecione uma marca')"
            :options="getComboBrands"
          />
        </b-col>
        <b-col md="4">
          <e-search-sku
            id="product-name"
            v-model="filters.skuId"
            :required="false"
          />
        </b-col>
        <b-col md="2">
          <FormulateInput
            id="active"
            v-model="filters.installed"
            type="vue-select"
            :label="$t('Instalado')"
            :placeholder="$t('Todos')"
            :options="yesNoTypes()"
          />
        </b-col>
      </b-row>
    </e-filters>

    <b-card-actions
      action-refresh
      :show-loading="fetching"
      @refresh="filterData"
    >
      <div slot="title">
        <e-page-size-selector
          :per-page="paging.pageSize"
          @change="pageSizeChange"
        />
      </div>

      <b-table
        ref="barrels-table"
        show-empty
        responsive
        striped
        class="bordered"
        :empty-text="$t('Nenhum barril encontrado')"
        :fields="fields"
        :items="barrels"
        no-local-sorting
        :sort-by.sync="sorting.sortBy"
        :sort-desc.sync="sorting.sortDesc"
        @sort-changed="getData"
      >
        <template #cell(action)="row">
          <span>
            <e-grid-actions
              :show-update="false"
              :show-delete="false"
              :buttons="[
                {
                  icon: 'search',
                  variant: 'primary',
                  title: $t('Visualizar histórico de consumo'),
                  event: 'product-read-only',
                },
              ]"
              @product-read-only="showConsumptionHistory(row)"
            />
          </span>
        </template>
        <template #cell(installed)="{ value }">
          <e-status-badge
            :status="value"
            type="yesNo"
          />
        </template>
        <template #cell(invoiceNumber)="row">
          <e-router-link
            v-if="row.item.purchaseOrder"
            :to="{ name: 'order-read-only', params: { id: row.item.purchaseOrderId } }"
            :title="$t('Visualizar Nota Fiscal')"
            :value="row.item.purchaseOrderId"
          />
        </template>
      </b-table>

      <b-row>
        <b-col cols="6">
          <e-pagination-summary
            :current-page="paging.currentPage"
            :per-page="paging.pageSize"
            :total="paging.rowCount"
            :total-on-page="paging.rowsInCurrentPage"
          />
        </b-col>
        <b-col cols="6">
          <b-pagination
            v-model="paging.currentPage"
            align="right"
            :total-rows="paging.rowCount"
            :per-page="paging.pageSize"
            aria-controls="product-table"
            @change="pageChange"
          />
        </b-col>
      </b-row>
    </b-card-actions>
  </section>
</template>

<script>
import { BRow, BCol, BPagination, BTable } from 'bootstrap-vue'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import {
  EPageSizeSelector,
  EPaginationSummary,
  EFilters,
  EGridActions,
  ESearchSku,
  EStoreCombo,
} from '@/views/components'
import { statusTypes } from '@/mixins'
import { mapActions, mapGetters, mapState } from 'vuex'
import EStatusBadge from '@/views/components/EStatusBadge.vue'

export default {
  components: {
    BRow,
    BCol,
    BCardActions,
    BPagination,
    BTable,
    EPaginationSummary,
    EPageSizeSelector,
    EFilters,
    EGridActions,
    EStatusBadge,
    ESearchSku,
    EStoreCombo,
  },

  mixins: [statusTypes],

  data() {
    return {
      fetching: false,
      edit: false,
      saving: false,
      product: {
        id: null,
        name: '',
      },
    }
  },

  computed: {
    ...mapState('pages/kiosk/barrels', ['filters', 'sorting', 'barrels', 'paging']),
    ...mapGetters('pages/catalog/products', ['getComboBrands']),
    fields() {
      return [
        {
          label: this.$t('Ações'),
          key: 'action',
          tdClass: 'text-center',
          thStyle: { width: '70px' },
        },
        {
          label: this.$t('Id'),
          key: 'palmBeerBarrelCode',
          tdClass: 'text-right',
          thStyle: { width: '80px' },
          sortable: true,
        },
        {
          label: this.$t('Produto'),
          key: 'sku.name',
          tdClass: 'text-left',
          sortable: true,
        },
        {
          label: this.$t('Loja'),
          key: 'store',
          tdClass: 'text-left',
          formatter: value => this.$options.filters.storeName(value),
          sortable: true,
        },
        {
          label: this.$t('Instalado'),
          key: 'installed',
          tdClass: 'text-center',
          thStyle: { width: '80px' },
        },
        {
          label: this.$t('Capacidade'),
          key: 'capacity',
          class: 'text-center',
          thStyle: { width: '80px' },
          formatter: value => `${this.$options.filters.number(value / 1000, 3, true)} L`,
          sortable: true,
        },
        {
          label: this.$t('Restante'),
          key: 'remainingLiquid',
          class: 'text-center',
          thStyle: { width: '80px' },
          formatter: value => `${this.$options.filters.number(value / 1000, 3, true)} L`,
          sortable: true,
        },
        {
          label: this.$t('NF'),
          key: 'invoiceNumber',
          tdClass: 'text-right',
          thStyle: { width: '100px' },
        },
      ]
    },
  },

  mounted() {
    this.getData()
  },

  methods: {
    ...mapActions('pages/kiosk/barrels', [
      'fetchBarrels',
      'setFilter',
      'setCurrentPage',
      'setPageSize',
      'resetFilters',
    ]),
    ...mapActions('pages/catalog/products', ['fetchDomainData']),

    async getData() {
      this.$nextTick(async () => {
        try {
          this.fetching = true
          await this.fetchBarrels()
          await this.fetchDomainData('brands')
        } catch (error) {
          this.showGenericError({ error })
        } finally {
          this.fetching = false
        }
      })
    },

    selectGroup(idValue) {
      this.filters.subgroup = null
      this.setComboSubgroups(idValue)
    },

    filterData() {
      this.setCurrentPage(1)
      this.getData()
    },

    resetFiltersLocal() {
      this.resetFilters()
      this.filterData()
    },

    pageSizeChange(pageSize) {
      this.setPageSize(pageSize)
      this.getData()
    },

    pageChange(currentPage) {
      this.setCurrentPage(currentPage)
      this.getData()
    },

    // eslint-disable-next-line no-unused-vars
    async showConsumptionHistory(row) {
      this.showWarning({ message: this.$t('Funcionalidade ainda não implementada') })
    },
  },
}
</script>

<style></style>
