<template>
  <b-modal
    id="modal-barrel-search"
    v-model="modal"
    :title="`${$t('Instalar Barril na Torneira')} #${tapNumber}`"
    no-close-on-backdrop
    hide-footer
    size="lg"
    scrollable
    centered
    @hidden="resetModal"
  >
    <b-container>
      <e-filters
        v-show="false"
        :searching="fetching"
        @search="filterData"
        @reset="resetFiltersLocal"
      >
        <b-row>
          <b-col md="6">
            <FormulateInput
              id="modalAdvProduct-brand"
              v-model="filters.brandId"
              type="vue-select"
              :label="$t('Marca')"
              :placeholder="$t('Todas')"
              :options="getComboBrands"
            />
          </b-col>
        </b-row>
      </e-filters>

      <b-card-actions
        :title="$t('Barris disponíveis')"
        :no-actions="barrels.length === 0"
        :action-refresh="barrels.length > 0"
        :show-loading="fetching"
        @refresh="filterData"
      >
        <div class="pb-1">
          <e-page-size-selector
            :per-page="paging.pageSize"
            @change="pageSizeChange"
          />
        </div>
        <b-table
          show-empty
          responsive
          striped
          class="bordered"
          :empty-text="getEmptyTableMessage($tc('KIOSK.BARREL.NAME'))"
          :fields="fields"
          :items="barrels"
        >
          <template #cell(imageSquare)="row">
            <b-img
              thumbnail
              :src="barrelImage(row.item)"
              :alt="`Imagem ${row.item.sku.name || 'sem imagem'}`"
              :style="{ height: 'auto', width: '150px', objectFit: 'contain' }"
            />
          </template>

          <template #cell(action)="row">
            <e-button
              variant="primary"
              :icon="'plus'"
              :title="$t('Instalar Barril na torneira')"
              @click="addBarrel(row.item)"
            />
          </template>
        </b-table>
        <b-row>
          <b-col cols="6">
            <e-pagination-summary
              :current-page="paging.currentPage"
              :per-page="paging.pageSize"
              :total="paging.rowCount"
              :total-on-page="paging.rowsInCurrentPage"
            />
          </b-col>
          <b-col cols="6">
            <b-pagination
              v-model="paging.currentPage"
              align="right"
              :total-rows="paging.rowCount"
              :per-page="paging.pageSize"
              aria-controls="client-table"
              @change="pageChange"
            />
          </b-col>
        </b-row>
      </b-card-actions>
    </b-container>
  </b-modal>
</template>

<script>
import { BModal, BContainer, BRow, BCol, BImg, BPagination, BTable } from 'bootstrap-vue'
import { formulateHelper } from '@/mixins'
import BCardActions from '@/@core/components/b-card-actions/BCardActions.vue'
import { EPaginationSummary, EFilters } from '@/views/components'
import EButton from '@/views/components/EButton.vue'
import { mapState, mapGetters, mapActions } from 'vuex'
import noImage from '@/assets/images/noimage.png'
import EPageSizeSelector from '@/views/components/pagination/EPageSizeSelector.vue'

export default {
  components: {
    BModal,
    BContainer,
    BRow,
    BImg,
    BCol,
    BPagination,
    BTable,
    BCardActions,
    EPaginationSummary,
    EButton,
    EFilters,
    EPageSizeSelector,
  },

  mixins: [formulateHelper],

  props: {
    beerTap: {
      type: Object,
      default: () => {},
    },
  },

  data() {
    return {
      fetching: false,
      noImage,
      modal: false,
    }
  },

  computed: {
    ...mapState('pages/kiosk/beerTaps', ['barrels', 'filters', 'paging']),
    ...mapGetters('pages/pdv/saleProducts', ['getComboBrands']),
    tapNumber() {
      return this.beerTap?.tapNumber
    },
    fields() {
      return [
        {
          label: '#',
          key: 'palmBeerBarrelCode',
          tdClass: 'text-center',
        },
        {
          label: '',
          key: 'imageSquare',
          class: 'text-center',
        },
        {
          label: this.$t('Nome'),
          key: 'sku.name',
          tdClass: 'text-left',
        },
        {
          label: this.$t('Restante'),
          key: 'remainingLiquid',
          tdClass: 'text-center',
          formatter: value =>
            value != null ? `${this.$options.filters.number(value / 1000, 2)} L` : 'Indeterminado',
        },
        {
          label: this.$t('Entrada'),
          key: 'createdDate',
          tdClass: 'text-center',
          formatter: value => this.$options.filters.datetime(value),
        },
        {
          label: this.$t('Ação'),
          key: 'action',
          tdClass: 'text-center',
        },
      ]
    },
    barrelImage() {
      return beerTap =>
        beerTap?.sku?.product?.imageSquare || this.noImage
    }
  },

  async mounted() {
    // await this.fetchProductCombos()
  },

  methods: {
    ...mapActions('pages/kiosk/beerTaps', [
      'fetchBarrels',
      'setCurrentPage',
      'setPageSize',
      // 'resetFilters',
    ]),

    filterData() {
      this.setCurrentPage(1)
      this.getData()
    },

    resetFiltersLocal() {
      // this.resetFilters()
    },

    pageChange(currentPage) {
      this.setCurrentPage(currentPage)
      this.getData()
    },
    pageSizeChange(pageSize) {
      this.setPageSize(pageSize)
      this.getData()
    },

    selectGroup(idValue) {
      this.filters.subgroup = null
      this.setComboSubgroups(idValue)
    },

    async getData() {
      try {
        this.fetching = true

        await this.fetchBarrels()
      } catch (error) {
        this.showError({ message: 'Problemas ao busca barris', error })
      } finally {
        this.fetching = false
      }
    },

    async addBarrel(barrel) {
      const confirm = await this.confirm({
        text: this.$t('KIOSK.BEER_TAP.CONFIRM_BARREL_ASSOCITATE', [
          barrel.sku.name,
          this.tapNumber,
        ]),
      })
      if (confirm) {
        this.$emit('add-barrel', { barrel, beerTap: this.beerTap })
        this.hideModal()
      }
    },

    resetModal() {
      // this.resetFilters()
    },

    hideModal() {
      this.modal = false
      // this.$bvModal.hide('modal-barrel-search')
    },

    async showModal() {
      try {
        this.modal = true
        // this.$bvModal.show('modal-barrel-search')
        await this.getData()
      } catch (error) {
        this.showError({ error })
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.fade-enter-active,
.fade-leave-active {
  transition: 0.2s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
  transform: translateY(-20px);
}
</style>
