var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "FormulateForm",
        [
          _c("FormulateInput", {
            attrs: {
              type: "group",
              label: " ",
              repeatable: !_vm.isReadOnly,
              "add-label": _vm.$t("Adicionar mídia"),
              "remove-position": "before",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var index = ref.index
                  return [
                    _c(
                      "b-row",
                      [
                        _c(
                          "b-col",
                          { attrs: { md: "3" } },
                          [
                            _c("FormulateInput", {
                              attrs: {
                                id: "files_form-tap-status-" + index,
                                name: "beerTapStatus",
                                type: "vue-select",
                                label: _vm.$t("Status da torneira"),
                                options: _vm.beerTapStatus,
                                disabled: _vm.isReadOnly,
                                clearable: !_vm.isReadOnly,
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "b-col",
                          { attrs: { md: "9" } },
                          [
                            _c("FormulateInput", {
                              attrs: {
                                id: "files_form-file-" + index,
                                name: "file",
                                type: "uploader",
                                "max-files": "1",
                                deletable: "false",
                                linkable: true,
                                readonly: _vm.fileIsReadOnly(index),
                                theme: "list",
                                label: _vm.$t(""),
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                },
              },
              {
                key: "remove",
                fn: function (ref) {
                  var indexItem = ref.index
                  var removeItem = ref.removeItem
                  return [
                    !_vm.isReadOnly
                      ? _c(
                          "div",
                          {
                            staticClass: "mb-1 position-absolute",
                            staticStyle: { right: "7px", top: "25%" },
                          },
                          [
                            _c("e-button", {
                              staticClass: "px-0",
                              attrs: {
                                title: _vm.$t("Deletar arquivo"),
                                icon: "trash",
                                variant: "danger",
                                size: "sm",
                              },
                              on: {
                                click: function () {
                                  return _vm.onRemoveDocument(
                                    indexItem,
                                    removeItem
                                  )
                                },
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                  ]
                },
              },
            ]),
            model: {
              value: _vm.localFiles,
              callback: function ($$v) {
                _vm.localFiles = $$v
              },
              expression: "localFiles",
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }