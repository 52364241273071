
import axios from '@axios'
import useJwt from '@/auth/jwt/useJwt'
import EventBus from '@/utils/EventBus'
import { getAppVersions } from '@/utils/app-utils'
import { storePagination } from '@/mixins/store'
import { sorting } from '@/mixins'
import managePayBoxCommands from './manage-pay-box-commands'

const getInitialState = () => ({
  ...storePagination.state(),
  commands: [],
  payBoxes: [],
  events: [],
  filters: {
    storeId: null,
    payboxId: null,
    statusCommand: 'Pending',
  },
  webSocket: {
    payboxCommands: {
      config: null,
      ready: false,
      connected: false,
      ws: null,
      currentChannelId: null,
    },
  },
  commandList: ['Ping'],
  command: null,
  statusCommandList: ['Executed', 'Canceled', 'Pending'],
})

export default {
  namespaced: true,
  state: getInitialState(),

  modules: {
    managePayBoxCommands
  },

  getters: {
    ready(state) {
      return state.webSocket.payboxCommands.ready
    },
    payboxCommandWebSocketConnected(state) {
      return state.webSocket.payboxCommands.connected
    },
    payBoxesOptions(state) {
      return state.payBoxes.map(pb => ({
        ...pb,
        value: pb.id,
        label: `Caixa ${pb.number}${pb.description ? ` - ${pb.description}` : ''}`,
      }))
    },
    commandsOptions(state){
      return state.commandList.map(cmd => ({
        ...cmd,
        value:cmd,
        label: cmd
      }))
    },
    statusCommandOptions(state){
      return state.statusCommandList.map(statusCommand => ({
        ...statusCommand,
        value: statusCommand,
        label: statusCommand
      }))
    }
  },

  mutations: {
    ...storePagination.mutations,
    SET_PAYBOX_COMMAND_READY(state, ready) {
      state.webSocket.payboxCommands.ready = ready
    },
    SET_PAYBOX_COMMAND_WEBSOCKET_CONNECTED(state, connected) {
      state.webSocket.payboxCommands.connected = connected
    },
    SET_PAYBOX_COMMAND_WS_CONFIG(state, data) {
      state.webSocket.payboxCommands.config = data
    },
    SET_PAY_BOXES(state, payBoxes) {
      state.payBoxes = payBoxes
    },
    SET_COMMANDS(state, commands){
      state.commands = commands
    },
    SET_FILTERS(state, filters) {
      state.filters = filters
    },
    CLEAN_STATE(state) {
      const { filters, paging, payBoxes, commands } = getInitialState()
      state.filters = filters
      state.payBoxes = payBoxes
      state.paging = paging
      state.commands = commands
    },
  },

  actions: {
    ...storePagination.actions,
    async fetchPayBoxCommandWebSocketConfig({ commit }) {
      const { data } = await axios.get('/api/settings/paybox-command/ws')
      commit('SET_PAYBOX_COMMAND_WS_CONFIG', data)
    },
    connectToPayBoxCommandsWebSocket({ commit, state, dispatch }) {
      const { webSocketEndpoint } = state.webSocket.payboxCommands.config
      console.debug('[payboxCommands websocket] Connecting to', webSocketEndpoint)

      state.webSocket.payboxCommands.ws = new WebSocket(`${webSocketEndpoint}?Token=${useJwt.getToken()}`)
      state.webSocket.payboxCommands.ws.onerror = error => {
        commit('SET_PAYBOX_COMMAND_READY', true)
        commit('SET_PAYBOX_COMMAND_WEBSOCKET_CONNECTED', false)
        console.debug('[payboxCommands websocket] Connection error', error)
      }

      state.webSocket.payboxCommands.ws.onopen = () => {
        console.debug('[payboxCommands websocket] Connection opened')
        dispatch('joinPayBoxCommandsChannel')
        commit('SET_PAYBOX_COMMAND_READY', true)
      }

      state.webSocket.payboxCommands.ws.onmessage = event => {
        const data = JSON.parse(event.data)
        dispatch('handleIncomingPayBoxCommandsEvent', data)
      }


      state.webSocket.payboxCommands.ws.onclose = () => {
        console.debug('[payboxCommands websocket] Connection closed')
        commit('SET_PAYBOX_COMMAND_WEBSOCKET_CONNECTED', false)
        dispatch('connectToPayBoxCommandsWebSocket')
      }

    },

    async joinPayBoxCommandsChannel({ commit, state, rootGetters }) {
      const {
        id: payBoxId,
      } = rootGetters['pages/pdv/payBoxConfiguration/currentPayboxConfiguration']

      if (state.webSocket.payboxCommands.ws) {
        if (state.webSocket.payboxCommands.currentChannelId) {
          const message = {
            action: 'leave',
            channelId: state.webSocket.payboxCommands.currentChannelId,
          }

          console.debug('[payboxCommands websocket] Leaving channel ', message.channelId)

          state.webSocket.payboxCommands.ws.send(JSON.stringify(message))
        }

        if (payBoxId) {
          const message = {
            action: 'join',
            channelId: `paybox-${payBoxId}-commands-events`,
          }

          console.debug('[payboxCommands websocket] Joining channel ', message.channelId)

          state.webSocket.payboxCommands.ws.send(JSON.stringify(message))

          commit('SET_PAYBOX_COMMAND_WEBSOCKET_CONNECTED', true)

          state.webSocket.payboxCommands.currentChannelId = message.channelId
        } else {
          commit('SET_PAYBOX_COMMAND_WEBSOCKET_CONNECTED', false)
        }
      }
    },

    async handleIncomingPayBoxCommandsEvent({ dispatch, state }, data) {
      console.debug('[payboxCommands websocket] receiving data', data)

      if (state.webSocket.payboxCommands.currentChannelId !== data.channelId) {
        console.debug('[payboxCommands websocket] received message from different channel, ignore', data)
        return
      }

      const content = JSON.parse(data.content)

      if(content.requiresUserConfirmation){
        EventBus.$emit('open-commands-modal', content, async response => {
          if(response){
            await dispatch('executeCommand', content)
          }else{
            await dispatch('updateCommandResponse', {id: content.id, status: 'Canceled'})
          }
        })
      }else{
        await dispatch('executeCommand', content)
      }
    },


    async executeCommand({dispatch}, content){
      let result = ''
      try{
        switch (content.command) {
          case 'Ping': {
            const responsePing = await window.electronAPI.pingPongUsingAgent('ping')
            console.debug('result ping pong: ', responsePing)
            result = {
              systemInfo: await window.electronAPI.system.systemInfo(),
              responsePingAgent: responsePing,
              appVersions: getAppVersions()
            }

            break
          }
          default:
            break
        }
      }catch(err){
        result = err.message
      }
      await dispatch('updateCommandResponse', {id: content.id, response: result, status: 'Executed', additionalInformation: ''})
    },

    async updateCommandResponse(store,{id, response, status, additionalInformation}){
      const data = {
        id,
        status,
        commandResponse: JSON.stringify(response),
        additionalInformation
      }

      await axios.put(
        '/api/sales/pay-box/command',
         data
      )
    },

    async fetchPayBoxes({ commit }, storeId) {
      const { data } = await axios.get(`/api/sales/pay-box`, {
        params: {
          storeId,
          pageSize: 999,
        },
      })

      commit('SET_PAY_BOXES', data.results || [])
    },

    async fetchCommands({ commit, state }){
      const { data } = await axios.get(`/api/sales/pay-box/command`, {
        params: {
          storeId: state.filters.storeId,
          payboxId: state.filters.payboxId,
          status: state.filters.statusCommand,
          pageSize: state.paging.pageSize,
          pageIndex: state.paging.currentPage - 1,
          sortBy: sorting.methods.getSorting(state)
        },
      })

      commit('SET_COMMANDS', data.results || [])
    },
    resetFilters({ commit }) {
      commit('SET_FILTERS', getInitialState().filters)
    },

    cleanState({ commit }) {
      commit('CLEAN_STATE')
    },
  }
}
