var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.cardClosed
    ? _c(
        "b-overlay",
        {
          attrs: {
            variant: "white",
            show: _vm.showLoadingLocal,
            "spinner-variant": "primary",
            blur: "0",
            opacity: ".75",
            rounded: "sm",
          },
        },
        [
          _c(
            "b-card",
            _vm._g(
              _vm._b(
                {
                  ref: "bCard",
                  style: _vm.cardStyles,
                  attrs: {
                    "no-body": "",
                    "aria-expanded": !_vm.content_visible ? "true" : "false",
                    "aria-controls": _vm.parentID,
                  },
                },
                "b-card",
                _vm.cardAttrs,
                false
              ),
              _vm.$listeners
            ),
            [
              _vm.showHeader
                ? _c(
                    "div",
                    { staticClass: "card-header" },
                    [
                      !_vm.$slots.title
                        ? _c(
                            "div",
                            [
                              _c("b-card-title", [
                                _vm._v(_vm._s(_vm.$attrs.title)),
                              ]),
                              _vm.$attrs["sub-title"]
                                ? _c("b-card-sub-title", [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.$attrs["sub-title"]) +
                                        " "
                                    ),
                                  ])
                                : _vm._e(),
                            ],
                            1
                          )
                        : _c("div", [_vm._t("title")], 2),
                      _vm.showActions || _vm.customActions.length
                        ? _c("b-card-actions-container", {
                            attrs: {
                              "available-actions": _vm.availableActions,
                              "is-collapsed": !_vm.content_visible,
                              "custom-actions": _vm.customActions,
                            },
                            on: {
                              collapse: _vm.triggerCollapse,
                              refresh: _vm.triggerRefresh,
                              close: _vm.triggerClose,
                              customAction: _vm.customAction,
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "b-collapse",
                {
                  staticClass: "card-content",
                  attrs: { id: _vm.parentID, visible: _vm.content_visible },
                  model: {
                    value: _vm.content_visible,
                    callback: function ($$v) {
                      _vm.content_visible = $$v
                    },
                    expression: "content_visible",
                  },
                },
                [
                  _vm.$attrs["no-body"] !== undefined
                    ? _vm._t("default")
                    : _c("b-card-body", [_vm._t("default")], 2),
                ],
                2
              ),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }