<template>
  <div>
    <FormulateForm
      ref="formTef"
      name="formTef"
    >
      <b-row>
        <b-col md="6">
          <b-card-actions
            no-actions
            :title="$t('Configuração do TEF')"
          >
            <b-row>
              <b-col md="12">
                <p class="h4">
                  {{ $t('Conexão') }}
                </p>
              </b-col>
              <b-col md="2">
                <FormulateInput
                  id="tef-config-connection-https"
                  v-model="payBox.tef.connectionHttps"
                  type="switch"
                  :label="$t('HTTPS')"
                /> </b-col><b-col md="10">
                <FormulateInput
                  id="tef-config-pinpad-model"
                  v-model="payBox.tef.pinpadModel"
                  :label="$t('Modelo PINPAD')"
                  type="vue-select"
                  :options="availablePinpadModels"
                  class="required"
                  validation="required"
                />
              </b-col>
            </b-row>

            <b-row v-if="!payBox.tef.connectionHttps">
              <b-col md="4">
                <FormulateInput
                  id="tef-config-connection-host"
                  v-model="payBox.tef.connectionHost"
                  :label="$t('Host')"
                  class="required"
                  validation="required"
                />
              </b-col>
              <b-col md="4">
                <FormulateInput
                  id="tef-config-connection-port"
                  v-model="payBox.tef.connectionPort"
                  type="number"
                  :label="$t('Porta')"
                  class="required"
                  validation="required"
                />
              </b-col>
              <b-col md="4">
                <FormulateInput
                  id="tef-config-connection-protocol"
                  v-model="payBox.tef.connectionProtocol"
                  type="vue-select"
                  :label="$t('Protocolo')"
                  :placeholder="$t('Selecione o protocolo')"
                  :options="protocolOptions"
                  class="required"
                  validation="required"
                />
              </b-col>
            </b-row>

            <b-row v-if="payBox.tef.connectionHttps">
              <b-col md="4">
                <FormulateInput
                  id="tef-config-auth-user"
                  v-model="payBox.tef.authUsername"
                  :label="$t('Usuário')"
                  class="required"
                  validation="required"
                />
              </b-col>
              <b-col md="4">
                <FormulateInput
                  id="tef-config-auth-pass"
                  v-model="payBox.tef.authPassword"
                  :label="$t('Senha')"
                  class="required"
                  validation="required"
                />
              </b-col>
              <b-col md="4">
                <FormulateInput
                  id="tef-config-auth-doc"
                  v-model="payBox.tef.authDocument"
                  :label="$t('CNPJ')"
                  class="required mb-0"
                  :mask="['##.###.###/####-##']"
                  :type="isProduction ? 'label' : 'text'"
                  filter="cpfCnpj"
                  validation="required"
                  aria-describedby="tef-config-auth-doc-helper-block"
                />
                <small
                  id="tef-config-auth-doc-helper-block"
                  class="form-text"
                >
                  {{ payBox.store | storeName }}
                </small>
              </b-col>

              <b-col md="12">
                <e-button
                  id="authenticate-tef-btn"
                  variant="outline-primary"
                  :text="$t('Autenticar')"
                  :disabled="busy"
                  @click="authenticate"
                />
              </b-col>

              <b-col md="12">
                <FormulateInput
                  id="authenticate-tef-result"
                  v-model="results.authenticate.msg"
                  type="textarea"
                  disabled="true"
                  class="required"
                  :label="$t('Resultado Autenticação')"
                  validation="authenticateValid"
                  :validation-rules="{
                    authenticateValid: () =>
                      payBox.tef.connectionHttps && !results.authenticate.error,
                  }"
                  :validation-messages="{
                    authenticateValid: $t('Resultado Autenticação deve ser sucesso'),
                  }"
                />
              </b-col>
            </b-row>

            <b-row>
              <b-col md="12">
                <p class="h4">
                  {{ $t('Configuração PDV') }}
                </p>
              </b-col>
              <b-col md="4">
                <FormulateInput
                  id="tef-config-business-code"
                  v-model="payBox.tef.businessCode"
                  :label="$t('Código do estabelecimento')"
                  class="required"
                  validation="required"
                  :disabled="payBox.tef.connectionHttps"
                />
              </b-col>
              <b-col md="4">
                <FormulateInput
                  id="tef-config-store-code"
                  v-model="payBox.tef.storeCode"
                  :label="$t('Código da loja')"
                  class="required"
                  validation="required"
                  :disabled="payBox.tef.connectionHttps"
                />
              </b-col>
              <b-col md="4">
                <FormulateInput
                  id="tef-config-terminal-code"
                  v-model="payBox.tef.terminalCode"
                  :label="$t('Código da terminal')"
                  class="required"
                  validation="required"
                  :disabled="payBox.tef.connectionHttps"
                />
              </b-col>
            </b-row>

            <b-row>
              <b-col md="12">
                <p class="h4">
                  {{ $t('Sistema Comercial') }}
                </p>
              </b-col>
              <b-col md="6">
                <FormulateInput
                  id="tef-config-system-name"
                  v-model="payBox.tef.systemName"
                  :label="$t('Nome do sistema')"
                  class="required"
                  validation="required"
                />
              </b-col>
              <b-col md="6">
                <FormulateInput
                  id="tef-config-system-version"
                  v-model="payBox.tef.systemVersion"
                  :label="$t('Versão do sistema')"
                  class="required"
                  validation="required"
                />
              </b-col>
            </b-row>

            <b-row>
              <b-col md="12">
                <p class="h4">
                  {{ $t('Outros') }}
                </p>
              </b-col>
              <b-col md="6">
                <FormulateInput
                  id="tef-config-enabled"
                  v-model="payBox.tef.enabled"
                  type="switch"
                  :label="$t('Habilita TEF')"
                />
              </b-col>
              <b-col md="6">
                <FormulateInput
                  id="tef-config-restart-device"
                  v-model="payBox.tef.restartDevice"
                  type="switch"
                  :instructions="[
                    {
                      text: $t(
                        'Com esta opção ativa em todas as operações relacionadas ao TEF iremos verificar se é necessário um restart do dispositivo PINPAD'
                      ),
                    },
                    {
                      text: $t(
                        'Operação é o equivalente a função Desinstalar do Gerenciador de Dispositivos do Windows'
                      ),
                    },
                    { text: $t('Somente tem efeito no Windows') },
                  ]"
                  :label="$t('Reiniciar dispositivo (Windows) caso necessário')"
                />
              </b-col>
              <b-col md="6">
                <FormulateInput
                  id="tef-config-restart-client-mode"
                  v-model="payBox.tef.restartClientMode"
                  :label="$t('Reiniciar serviço (CTFClient)')"
                  type="vue-select"
                  :options="availableRestartClientMode"
                  :clearable="false"
                  class="required"
                  validation="required"
                />
              </b-col>
              <b-col md="6">
                <FormulateInput
                  id="tef-config-warm-client-mode"
                  v-model="payBox.tef.warmClientMode"
                  :label="$t('Inicializar client')"
                  type="vue-select"
                  :options="availableWarmClientMode"
                  :clearable="false"
                  :instruction="
                    $t(
                      'Esta opção configura o Inicializar Client antes de uma operação de venda, adiantando esta operação para evitá-la nas transações de pagamentos que podem algumas vezes gerar erro'
                    )
                  "
                  class="required"
                  validation="required"
                />
              </b-col>

              <b-col md="4">
                <FormulateInput
                  id="tef-config-log"
                  v-model="payBox.tef.log"
                  type="switch"
                  :label="$t('Habilita Log')"
                />
              </b-col>
              <b-col md="4">
                <FormulateInput
                  id="tef-config-cryptography"
                  v-model="payBox.tef.cryptography"
                  type="switch"
                  :label="$t('Habilita Criptografia')"
                />
              </b-col>
              <b-col md="4">
                <FormulateInput
                  id="tef-config-interactive"
                  v-model="payBox.tef.interactive"
                  type="switch"
                  :label="$t('Modo Interativo')"
                />
              </b-col>
            </b-row>
          </b-card-actions>
        </b-col>
        <b-col md="6">
          <b-card-actions
            no-actions
            :title="$t('Testes TEF')"
          >
            <b-row>
              <b-col md="12">
                <e-button
                  id="restart-services-tef-btn"
                  variant="outline-primary"
                  :text="$t('Reiniciar serviço (PINPAD)')"
                  @click="stRestartService"
                />
              </b-col>
            </b-row>
            <br>
            <b-row>
              <b-col md="12">
                <e-button
                  id="auto-configuration-pinpad-tef-btn"
                  variant="outline-primary"
                  :text="$t('Configuracao Automática do Pinpad (Windows)')"
                  :disabled="busy"
                  @click="autoConfigurationPinpad"
                />
              </b-col>

              <b-col md="12">
                <FormulateInput
                  id="auto-configuration-pinpad-result"
                  v-model="results.autoConfigurationPinpad.msg"
                  type="textarea"
                  disabled="true"
                  :label="$t('Resultado Configuracao Automática do Pinpad')"
                />
              </b-col>
            </b-row>

            <b-row>
              <b-col md="12">
                <e-button
                  id="initialize-tef-btn"
                  variant="outline-primary"
                  :text="$t('Inicializar client')"
                  :disabled="busy"
                  @click="initialize"
                />
              </b-col>

              <b-col md="12">
                <FormulateInput
                  id="initialize-tef-result"
                  v-model="results.initialize.msg"
                  type="textarea"
                  disabled="true"
                  class="required"
                  :label="$t('Resultado Inicializar')"
                  validation="initializeValid"
                  :validation-rules="{ initializeValid: () => !results.initialize.error }"
                  :validation-messages="{
                    initializeValid: $t('Resultado Inicializar deve ser sucesso'),
                  }"
                />
              </b-col>
            </b-row>

            <b-row>
              <b-col md="12">
                <e-button
                  id="refresh-tables-tef-btn"
                  variant="outline-primary"
                  :text="$t('Atualizar Tabelas')"
                  :disabled="busy"
                  @click="refreshTables"
                />
              </b-col>

              <b-col md="12">
                <FormulateInput
                  id="refresh-tables-result"
                  v-model="results.refreshTables.msg"
                  type="textarea"
                  disabled="true"
                  :label="$t('Resultado Atualizar Tabelas')"
                />
              </b-col>
            </b-row>

            <b-row>
              <b-col md="12">
                <e-button
                  id="print-last-tef-btn"
                  variant="outline-primary"
                  :text="$t('Reimprimir último recibo')"
                  :disabled="busy"
                  @click="printLastReceipt"
                />
              </b-col>

              <b-col md="12">
                <FormulateInput
                  id="print-last-result"
                  v-model="results.printLastReceipt.msg"
                  type="textarea"
                  disabled="true"
                  :label="$t('Resultado Reimprimir último recibo')"
                />
              </b-col>
            </b-row>

            <b-row>
              <b-col md="12">
                <p class="h4">
                  {{ headerTefMessages }}
                </p>
              </b-col>

              <b-col md="12">
                <b-table
                  ref="tef-messages-table"
                  show-empty
                  responsive
                  striped
                  hover
                  :fields="tefMessagesFields"
                  :items="tefLastMessages"
                />
              </b-col>
            </b-row>
          </b-card-actions>
        </b-col>
      </b-row>
    </FormulateForm>
  </div>
</template>

<script>
import { BRow, BCol, BTable } from 'bootstrap-vue'
import BCardActions from '@/@core/components/b-card-actions/BCardActions.vue'
import EButton from '@/views/components/EButton.vue'
import { alerts } from '@/mixins'
import { mapState, mapActions } from 'vuex'

const environment = window.env || 'local'
const isProduction = environment === 'production'

export default {
  components: {
    BCardActions,
    BRow,
    BCol,
    BTable,
    EButton,
  },
  mixins: [alerts],
  data() {
    return {
      isProduction,
      messagesToShow: 10,
      availablePinpadModels: [
        { value: 'PPC920', label: 'PPC920 (QrCode PIX na tela do sistema, sem suporte no PINPAD)' },
        { value: 'PPC930', label: 'PPC930 (Suporta QrCode do PIX no visor do PINPAD)' },
      ],
      availableRestartClientMode: [
        { value: 'Always', label: 'Em toda operação' },
        { value: 'OnError', label: 'Quando acontecer um erro' },
        { value: 'Disabled', label: 'Desabilitado' },
      ],
      availableWarmClientMode: [
        { value: 'PdvStart', label: 'Ao abrir o PDV' },
        { value: 'Disabled', label: 'Desabilitado' },
      ],
      protocolOptions: ['TCP', 'UDP'],
      busy: false,
      results: {
        initialize: {
          msg: null,
          error: true,
        },
        authenticate: {
          msg: null,
          error: true,
        },
        refreshTables: {
          msg: null,
          error: true,
        },
        printLastReceipt: {
          msg: null,
          error: true,
        },
        autoConfigurationPinpad: {
          msg: null,
          error: true,
        },
      },
    }
  },
  computed: {
    ...mapState('pages/pdv/payBoxTef', ['lastMessages']),
    ...mapState('pages/pdv/payBoxConfiguration', ['payBox']),
    headerTefMessages() {
      return this.$t(`Últimas mensagens (${this.messagesToShow}) TEF`)
    },
    tefMessagesFields() {
      return [
        {
          label: this.$t('Mensagem'),
          key: 'message',
        },
      ]
    },
    tefLastMessages() {
      return this.lastMessages.slice(0, this.messagesToShow)
    },
  },
  methods: {
    ...mapActions('pages/pdv/payBoxTef', {
      stInitialize: 'initialize',
      stAuthenticate: 'authenticate',
      stPrintLastReceipt: 'printLastReceipt',
      stRefreshTables: 'refreshTables',
      stAutoConfigurationPinpad: 'autoConfigurationPinpad',
      stRestartService: 'restartService',
    }),
    skipStep() {
      this.payBox.tef.skipped = true
    },
    canSkip() {
      return this.payBox.tef.skipped
    },
    validateForm() {
      const valid = this.$refs.formTef.isValid
      if (!valid) {
        this.showInvalidDataMessage()
        this.$refs.formTef.showErrors()
      } else {
        this.payBox.tef.skipped = false
      }

      return valid
    },

    async initialize() {
      try {
        this.busy = true
        this.results.initialize.msg = 'Executando...'
        const result = await this.stInitialize()
        console.debug('initialize', result)
        this.results.initialize.error = false
        this.results.initialize.msg = this.$t(result)
          ? JSON.stringify(result, null, 2)
          : this.$t('finalizado')
      } catch (err) {
        console.error('initialize:', err)
        this.results.initialize.error = true
        this.results.initialize.msg = this.$t(err)
      } finally {
        this.busy = false
      }
    },
    async printLastReceipt() {
      try {
        this.busy = true
        this.results.printLastReceipt.msg = 'Executando...'
        const result = await this.stPrintLastReceipt()
        console.debug('printLastReceipt', result)
        this.results.printLastReceipt.error = false
        this.results.printLastReceipt.msg = this.$t(result)
          ? JSON.stringify(result, null, 2)
          : this.$t('finalizado')
      } catch (err) {
        console.error('printLastReceipt:', err)
        this.results.printLastReceipt.error = true
        this.results.printLastReceipt.msg = this.$t(err)
      } finally {
        this.busy = false
      }
    },
    async authenticate() {
      try {
        this.busy = true
        this.results.authenticate.msg = 'Executando...'
        const result = await this.stAuthenticate()
        console.debug('authenticate', result)

        this.payBox.tef.businessCode = result.codes.businessCode
        this.payBox.tef.storeCode = result.codes.storeCode
        this.payBox.tef.terminalCode = result.codes.terminalCode
        this.results.authenticate.error = false
        this.results.authenticate.msg = this.$t(result)
          ? JSON.stringify(result, null, 2)
          : this.$t('finalizado')
      } catch (err) {
        console.error('authenticate:', err)
        this.results.authenticate.error = true
        this.results.authenticate.msg = this.$t(err)
      } finally {
        this.busy = false
      }
    },
    async refreshTables() {
      try {
        this.busy = true
        this.results.refreshTables.msg = 'Executando...'
        const result = await this.stRefreshTables()
        console.debug('refreshTables', result)
        this.results.refreshTables.error = false
        this.results.refreshTables.msg = this.$t(result)
          ? JSON.stringify(result, null, 2)
          : this.$t('finalizado')
      } catch (err) {
        console.error('refreshTables:', err)
        this.results.refreshTables.error = true
        this.results.refreshTables.msg = this.$t(err)
      } finally {
        this.busy = false
      }
    },
    async autoConfigurationPinpad() {
      try {
        this.busy = true
        this.results.autoConfigurationPinpad.msg = 'Executando...'
        const result = await this.stAutoConfigurationPinpad()
        console.debug('autoConfigurationPinpad', result)
        this.results.autoConfigurationPinpad.error = false
        this.results.autoConfigurationPinpad.msg = this.$t(result)
          ? JSON.stringify(result, null, 2)
          : this.$t('finalizado')
      } catch (err) {
        console.error('autoConfigurationPinpad:', err)
        this.results.autoConfigurationPinpad.error = true
        this.results.autoConfigurationPinpad.msg = this.$t(err)
      } finally {
        this.busy = false
      }
    },
  },
}
</script>

<style lang="scss" scoped></style>
