import _ from 'lodash'
import { roundDecimal } from '@/utils/number-utils'
import Decimal from 'decimal.js-light'
import { contactUtils, payBoxUtils } from '../utils'
import productDomains from './product-domains'
import discountTypes from './discount-types'
import personTypes from './person-types'
import address from '../address'
import paymentTypes from './payment-types'

const getInitialPayBoxItem = () => ({
  id: null,
  product: null,
  orderAmount: 0,
  amount: 1,
  maxAmount: -1,
  discountType: discountTypes.computed.discountTypesEnum().VALUE,
  inputMethodEnum: null,
  discount: 0,
  observation: '',
  priceTableId: null,
  promotionId: null,
  discountPriceTable: 0,
  unitValue: 0,
  subtotal: 0,
  returnDate: null, // data que comodato/aluguel retornou na loja
  returnDateLimit: null, // data limite do retorno de comodota ou aluguel
  deliveredDate: null,
  createdDate: null,
  createdBy: '',
  returnQuantityDamaged: 0,
  priceInfo: {
    priceWithDiscount: 0,
    valueDiscounted: 0,
    subtotalWithoutDiscount: 0,
  },
  palmBeerCardNumber: null,
  choppGiftCardNumber: null,
  isDeleted: false,
})

const getInitialPayBoxReturnedItem = () => ({
  itemReturnedId: null,
  itemId: null,
  product: null,
  kit: null,
  lendingProduct: null,
  amount: 0,
  maxAmount: 0,
  damagedAmount: 0,
  discountType: discountTypes.computed.discountTypesEnum().VALUE,
  unitDiscount: 0,
  unitValue: 0,
  subtotal: 0,
  unitValueCalculated: 0,
  subtotalCalculated: 0,
  returnDate: null,
  returnDateLimit: null,
  willReturn: false,
})

const getInitialConsignReturnForm = () => ({
  returnedItems: [],
  returnedLendingItems: [],
  value: 0,
  paymentMethod: null,
  observation: '',
})

const getInitialReceiptForm = () => ({
  id: null,
  paymentMethod: {
    id: null,
    name: '',
    method: '',
  },
  paymentMethodInterface: paymentTypes.data().paymentMethodInterfaceEnum.TEF,
  value: 0,
  canceled: false,
  change: 0,
  nsu: null,
  documentTitular: null,
  cardBrand: null,
})

const getInitialDeliveryForm = () => ({
  address: address.data().address,
  observation: '',
  addressSelected: 'other',
  deliveryTaxDiscountValue: 0,
  deliveryTax: 0,
  deliveryStatus: null,
  deliveryDate: null,
})

const getInitialPalmBeerCardData = () => ({
  customer: { id: null },
})

const getInitialCancelationSaleData = () => ({
  cancelationUser: { id: null, name: null },
  cancelationDate: null,
  cancelationReason: null,
})

const getInitialPayBoxSale = () => ({
  id: null,
  uuid: null,
  barTabConsumption: {
    id: null,
    barTab: {
      id: null,
      number: '',
    },
  },
  grossValue: 0,
  netValue: 0,
  comission: 0,
  comissionFee: null,
  paymentStarted: false,
  items: [],
  invoiceReturn: null,
  offline: false,
  priceTable: null,
  itemForm: getInitialPayBoxItem(),
  clientForm: {
    id: null,
    personType: personTypes.computed.personTypeEnum().CONSUMER,
    landLine: '',
    mobilePhone: '',
    email: '',
    site: '',
    observation: '',
    document: '', // represent CNPJ or CPF
    name: '', // represent name or TradingName
    priceTable: {},

    gender: '',
    birthdate: null,

    companyName: '',
    stateRegistrationType: '',
    stateRegistration: '',
    municipalInscription: '',

    address: address.data().address,
    addressList: [],
  },
  paymentForm: {
    discount: 0,
    receipts: [],
    change: 0,
    balance: 0,
  },
  pendingPaymentValue: 0,
  isDelivery: false,
  isConsigned: false,
  consignedMaxReturnValue: 0,
  deliveryForm: getInitialDeliveryForm(),
  returnForm: getInitialConsignReturnForm(),
  returnSale: {
    value: 0,
  },
  palmBeerData: getInitialPalmBeerCardData(),
  invoice: null,
  user: null,
  status: null,
  payBoxId: null,
  store: null,
  cancelationData: getInitialCancelationSaleData(),
})

const payBoxSale = {
  data() {
    return {
      getInitialPayBoxSale,
    }
  },

  computed: {},

  methods: {
    getSubtotalSaleWithoutDiscount(saleItems) {
      // saleItems deve ter sido formatado antes pelo método `prepareSaleAndKitItems()`
      const result = saleItems.reduce(
        (total, item) => total + item.priceInfo?.subtotalWithoutDiscount,
        0
      )
      return roundDecimal(result)
    },
    getTotalItemsDiscount(saleItems) {
      const result = saleItems.reduce(
        (total, item) => total + item.priceInfo?.valueDiscounted || 0,
        0
      )
      return roundDecimal(result)
    },
    /**
     * Pegar a quantidade de determinado produto na venda (já pega cenários de produtos iguais separados e dentro de kit)
     */
    getProductQuantityInSale(saleItems, skuId, options = { originalOrder: false }) {
      const getAmount = item => (options.originalOrder ? item.orderAmount : item.amount) || 0

      const items = options.originalOrder ? saleItems?.filter(i => i.id) : saleItems
      const result = items?.reduce((qttTotal, item) => {
        if (skuId === item.product?.skuId) return qttTotal + getAmount(item)

        const qttProductInKitItems = item.product?.kitItems?.reduce((sum, ki) => {
          if (ki.product?.skuId === skuId) return sum + getAmount(ki)
          return sum
        }, 0)
        if (qttProductInKitItems > 0) return qttTotal + qttProductInKitItems

        return qttTotal
      }, 0)

      return result || 0
    },

    getKitQuantityInSale(saleItems, productKitId, options = { originalOrder: false }) {
      const getAmount = item => (options.originalOrder ? item.orderAmount : item.amount) || 0
      const saleItemKits = saleItems.filter(i => i.product.kit)
      const items = options.originalOrder ? saleItemKits?.filter(i => i.id) : saleItemKits

      const result = items.reduce((sum, ki) => sum + getAmount(ki), 0)
      return result
    },

    // #region SaleItem formatting
    prepareSale(saleData) {
      const {
        id: saleDataId,
        uuid,
        barTabConsumption,
        customer,
        priceTable,
        discountType: saleDiscountType,
        discount: saleDiscount,
        delivery: isDelivery,
        consigned: isConsigned,
        consignedMaxReturnValue,
        deliveryTaxDiscountValue,
        deliveryTax,
        deliveryObservation,
        deliveryAddress,
        deliveryDate,
        deliveryStatus,
        offline,
        items,
        itemsReturned,
        itemKits,
        payments,
        grossValue: saleGrossValue,
        netValue: saleNetValue,
        comission,
        returnSale,
        invoice,
        user,
        cancelationUser,
        cancelationDate,
        cancelationReason,
        status,
        payBoxId,
        store,
        pendingPaymentValue,
        origin,
        reason,
      } = saleData

      const { cellphone, phone } = contactUtils.getPricipalMobileAndLandline(customer.telephones)
      const defaultData = getInitialPayBoxSale()

      const receipts = payments.map(p => ({
        ...getInitialReceiptForm(),
        ...p,
      }))
      const itemsOfSale = this.prepareSaleItemAndKits(items, itemKits)
      const productTotalValue = itemsOfSale.reduce((total, i) => total + i.subtotal, 0)

      const formatedData = {
        ...defaultData,
        invoice,
        user,
        status,
        origin,
        payBoxId,
        store,
        id: saleDataId,
        uuid,
        barTabConsumption,
        comission,
        comissionFee: barTabConsumption?.comissionFee ?? null,
        grossValue: saleGrossValue,
        netValue: saleNetValue,
        pendingPaymentValue,
        priceTable,
        offline,
        reason,
        items: itemsOfSale,
        itemsReturned,
        returnSale,
        // tipo do desconto está apenas para valor (quando vier em porcentagem, é convertido para valor)
        paymentForm: {
          ...defaultData.paymentForm,
          discount: payBoxUtils.getDiscountValue({
            discountType: saleDiscountType,
            discountValue: saleDiscount,
            totalValue: productTotalValue,
          }),
          balance: 0,
          receipts,
        },
        isDelivery,
        isConsigned,
        consignedMaxReturnValue: consignedMaxReturnValue || 0,
        deliveryForm: {
          ...defaultData.deliveryForm,
          address: deliveryAddress,
          observation: deliveryObservation,
          addressSelected: '',
          deliveryTax,
          deliveryTaxDiscountValue,
          deliveryStatus,
          deliveryDate,
        },
        clientForm: {
          ...defaultData.clientForm,
          ...customer,
          landLine: phone,
          mobilePhone: cellphone,
          site: customer.siteUrl,
          address:
            customer.addresses.length > 0 ? customer.addresses[0] : defaultData.clientForm.address,
          addressList: customer.addresses || [],
        },
        cancelationData: {
          ...defaultData.cancelationData,
          user: cancelationUser,
          date: cancelationDate,
          reason: cancelationReason,
        },
      }

      if (isConsigned) {
        formatedData.returnForm = this.prepareReturnedItems(
          formatedData.items,
          formatedData.itemsReturned,
          returnSale
        )
      }

      return formatedData
    },

    /**
     * Formatar os itens que vem da API com propriedade skuEnriched para agrupar comodatos associados a itens e itens dentro de kit
     * @param {Array} apiItems items da venda
     * @param {Array} apiItemKits kits da venda
     * @returns
     */
    prepareSaleItemAndKits(apiItems, apiItemKits) {
      const itemsFormated = this.prepareSaleItems(apiItems)
      const saleItems = itemsFormated.filter(i => !i.productKitId)
      const kitItems = this.prepareItemKits(itemsFormated, apiItemKits)

      return [...saleItems, ...kitItems]
    },

    prepareItemKits(saleItemsFormated, itemKits) {
      const defaultData = getInitialPayBoxItem()
      const itemsFromKit = saleItemsFormated.filter(i => i.productKitId)
      const result = itemKits.map(kit => {
        const itemsOfKit = itemsFromKit
          .filter(i => i.productKitId === kit.productKit.id)
          .map(i => {
            const product = kit.productKitEnriched.kitItems.find(
              ki => ki.skuId === i.product.skuId
            )

            return {
              ...i,
              product: { ...product, lendingItemAssociated: i.product?.lendingItemAssociated },
            }
          })

        const kitTotalValue = itemsOfKit.reduce(
          (total, i) => new Decimal(total).add(i.subtotal || 0).toNumber(),
          0
        )
        return {
          ...defaultData,
          id: kit.id,
          amount: kit.quantity,
          product: {
            ...kit.productKitEnriched,
            kitItems: itemsOfKit,
          },
          subtotal: kitTotalValue,
          unitValue: kit.unitValue,
          priceInfo: {
            priceWithDiscount: kitTotalValue,
            valueDiscounted: 0,
            subtotalWithoutDiscount: kitTotalValue,
          },
        }
      })

      return result
    },

    prepareSaleItems(items) {
      const defaultData = getInitialPayBoxItem()
      const { LENDING } = productDomains.computed.productClassificationsEnum()

      const lendingItems = items.filter(i => i.classification === LENDING)

      // const isLendingItemInKit = i => i.productKitId && i.classification === LENDING
      return _.sortBy(items, ['id'])
        .filter(i => !i.saleItemAssociatedId)
        .map(i => {
          const discountValue = payBoxUtils.getDiscountValue({
            discountType: i.discountType,
            discountValue: i.unitDiscount,
            totalValue: i.unitValue,
            priceTableDiscount: i.discountPriceTable,
          })
          const lp = i.skuEnriched?.lendingProductAssociated
          let localMainSkuEnriched = i.skuEnriched || i.sku

          const itemLpFound = lendingItems.find(
            lendingItem => i.id === lendingItem.saleItemAssociatedId
          )
          if (lp && itemLpFound) {
            const lendingItemsList = !itemLpFound
              ? []
              : [itemLpFound].map(itemLp => ({
                  ...defaultData,
                  // ...itemLp,
                  id: itemLp.id,
                  amount: itemLp.quantity,
                  product: {
                    ...lp,
                    skuId: itemLp.sku.id,
                  },
                  saleItemAssociatedId: itemLp.saleItemAssociatedId,
                  skuAssociatedId: itemLp.skuAssociatedId,
                  priceTableId: itemLp.priceTableId,
                  inputMethodEnum: i.inputMethod,
                  returnDate: itemLp.returnDate,
                  returnDateLimit: itemLp.returnDateLimit,
                  deliveredDate: i.deliveredDate,
                  createdDate: i.createdDate,
                  createdBy: i.createdBy,
                  returnQuantityDamaged: itemLp.returnQuantityDamaged,
                  palmBeerCardNumber: i.palmBeerCardNumber || null,
                  choppGiftCardNumber: i.choppGiftCardNumber || null,
                }))

            localMainSkuEnriched = {
              ...localMainSkuEnriched,
              lendingItemAssociated: lendingItemsList,
            }
          }

          return {
            ...defaultData,
            id: i.id,
            classification: i.classification,
            productKitId: i.productKitId,
            inputMethodEnum: i.inputMethod,
            discountType: i.discountType,
            discount: i.unitDiscount,
            observation: i.observation,
            product: localMainSkuEnriched,
            amount: i.quantity,
            maxAmount: i.promotionId ? i.quantity : defaultData.maxAmount,
            priceTableId: i.priceTableId,
            promotionId: i.promotionId,
            discountPriceTable: i.discountPriceTable || 0,
            unitValue: i.unitValue,
            subtotal: i.netValue,
            returnDate: i.returnDate,
            returnDateLimit: i.returnDateLimit,
            deliveredDate: i.deliveredDate,
            createdDate: i.createdDate,
            createdBy: i.createdBy,
            returnQuantityDamaged: i.returnQuantityDamaged,
            palmBeerCardNumber: i.palmBeerCardNumber || null,
            choppGiftCardNumber: i.choppGiftCardNumber || null,
            priceInfo: {
              priceWithDiscount: roundDecimal(i.unitValue - discountValue, 4),
              valueDiscounted: roundDecimal(i.grossValue - i.netValue, 4),
              subtotalWithoutDiscount: i.grossValue,
            },
          }
        })
    },

    prepareReturnedItems(items, itemsReturned, returnSale) {
      const { SALE, RENT, LENDING } = productDomains.computed.productClassificationsEnum()
      const defaultItemReturned = getInitialPayBoxReturnedItem()
      const itemsFormatted = items
        .map(i => {
          const itemList = []

          if (i.product.kitItems) {
            return i.product.kitItems.map(ki => ({
              ...ki,
              kitData: i.product,
            }))
          }

          itemList.push(i)
          const lendingItemList = i.product.lendingItemAssociated
          if (lendingItemList && Array.isArray(lendingItemList)) {
            lendingItemList.forEach(li => {
              itemList.push({
                ...li,
                lendingData: i.product, // TODO mudar para AssociatedData
              })
            })
          }
          return itemList
        })
        .flat()

      const validItemsReturned = Array.isArray(itemsReturned) ? itemsReturned : []
      const alreadyReturnedFormatted = validItemsReturned.map(ir => {
        const priceInfo = payBoxUtils.calculateItemPrice({
          price: ir.unitValue,
          quantity: ir.quantity,
          discountType: ir.discountType,
          unitDiscount: ir.unitDiscount,
        })
        return {
          ...defaultItemReturned,
          itemReturnedId: ir.id,
          itemId: ir.saleItemId,
          product: { ...ir.sku, classification: ir.classification, skuId: ir.sku.id },
          amount: ir.quantity,
          maxAmount: ir.quantity,
          unitValue: ir.unitValue,
          subtotal: ir.netValue,
          unitValueCalculated: priceInfo.priceInfo.priceWithDiscount,
          subtotalCalculated: priceInfo.netValue,
          returnDate: ir.createdDate,
          deliveredDate: ir.deliveredDate,
          createdDate: ir.createdDate,
          createdBy: ir.createdBy,
          discountType: ir.discountType,
          unitDiscount: ir.unitDiscount,
        }
      })

      const formatted = itemsFormatted.map(i => ({
        ...defaultItemReturned,
        itemId: i.id,
        product: i.product,
        maxAmount: i.amount,
        damagedAmount: i.returnQuantityDamaged || 0,
        unitValueCalculated: i.priceInfo.priceWithDiscount,
        subtotalCalculated: 0,
        kit: i.kitData || defaultItemReturned.kit,
        lendingProduct: i.lendingData || defaultItemReturned.lendingProduct,
        returnDate: i.returnDate || defaultItemReturned.returnDate,
        returnDateLimit: i.returnDateLimit || defaultItemReturned.returnDateLimit,
        deliveredDate: i.deliveredDate,
        createdDate: i.createdDate,
        createdBy: i.createdBy,
      }))

      const allItems = [...alreadyReturnedFormatted, ...formatted]

      const defaultReturnForm = getInitialConsignReturnForm()
      return {
        ...defaultReturnForm,
        returnedItems: allItems.filter(i => i.product.classification === SALE),
        returnedLendingItems: allItems.filter(
          i => i.product.classification === LENDING || i.product.classification === RENT
        ),
        value: returnSale?.value ?? defaultReturnForm.value,
        paymentMethod: returnSale?.paymentMethod ?? defaultReturnForm.paymentMethod,
        observation: returnSale?.observation ?? defaultReturnForm.observation,
      }
    },
    // #endregion

    // #region Product formatting
    prepareProductToSaleItem(amount, product) {
      let localProduct = product
      const lp = product.lendingProductAssociated
      if (lp) {
        localProduct = {
          ...localProduct,
          lendingItemAssociated: [
            {
              ...getInitialPayBoxItem(),
              product: { ...lp },
              amount,
              priceTableId: localProduct.priceTable.id,
              contractualFine: lp.contractualFine,
              inputMethodEnum: null,
            },
          ],
        }
      }

      return localProduct
    },
    // #endregion

    /** inicialmente utilizado para formar a key do dicionario de itens com quantidade */
    getSaleProductKey({ skuId, promotionId, kitId }) {
      return `sku${skuId || 0}_promo${promotionId || 0}_kit${kitId || 0}`
    },

    findProductInSaleItem(product) {
      return saleItem =>
        saleItem.product.id === product.id &&
        saleItem.product.kit === product.kit &&
        saleItem.product.promotionId === (product.promotionId ?? null)
    },
  },
}

export default payBoxSale
