<template>
  <div>
    <e-sidebar
      :title="isPendingLayout ? $t('Venda Pendente') : $t('Gerenciar venda')"
      :show="showSidebar"
      :fetching="fetching"
      :saving="saving"
      width="900px"
      @hidden="hide"
    >
      <template #content>
        <b-card-actions
          no-actions
          :title="$t('Dados da venda')"
        >
          <b-row>
            <b-col md="3">
              <FormulateInput
                id="sidebar_info_sale-sale_id"
                v-model="saleInfo.id"
                :label="$t('Venda')"
                type="label"
              />
            </b-col>
            <b-col md="6">
              <FormulateInput
                id="sidebar_info_sale-customer"
                :value="getCustomerName"
                :label="$t('Cliente')"
                type="label"
              />
            </b-col>

            <b-col md="3">
              <FormulateInput
                id="sidebar_info_sale-status"
                :value="saleInfo.status ? saleInfo.status : '-'"
                :label="$t('Status da venda')"
                type="label"
                :options="saleStatusOptions()"
                is-badge
              />
            </b-col>
          </b-row>

          <b-row class="mb-1">
            <b-col md="2">
              <p class="h6">
                {{ $t('Entrega?') }}
              </p>
              <e-status-badge
                :status="saleInfo.isDelivery"
                type="yesNo"
              />
            </b-col>

            <b-col md="2">
              <p class="h6">
                {{ $t('Consignado?') }}
              </p>
              <e-status-badge
                :status="isConsigned"
                type="yesNo"
              />
            </b-col>

            <b-col
              v-if="getBarTabConsumptionId"
              md="3"
            >
              <FormulateInput
                id="sidebar_info_sale-consumption_id"
                :value="saleInfo.barTabConsumption.id"
                :label="$t('Consumo no Bar')"
                type="label"
                :icons="consumptionIdLabelIcons"
                @link="showBarConsumption"
              />
            </b-col>

            <b-col
              v-if="saleInfo.invoice && saleInfo.invoice.model === 'NFE'"
              md="2"
            >
              <FormulateInput
                id="order_maintain-invoice-id"
                :value="`${saleInfo.invoice.invoiceNumber} | ${
                  invoiceModelLabelShort[saleInfo.invoice.model]
                }`"
                type="label"
                :label="$t('Nota fiscal')"
                router-name="invoice-maintain"
                :router-params="{ id: saleInfo.invoice.id }"
                :router-title="`${$t('Visualizar nota fiscal')} - ${
                  statusInvoiceLabel[saleInfo.invoice.status]
                }`"
              />
            </b-col>
          </b-row>

          <b-row class="mb-1">
            <b-col>
              <p class="h5">
                {{ $t('Produtos') }}:
              </p>

              <e-pay-box-sale-item-table
                class="bordered"
                :table-items="getItems"
                item-read-only
                prices-to-right
              />

              <!-- <b-table
                class="bordered mb-1"
                striped
                :fields="itemFields"
                :items="getItems"
              >
                <template #cell(price)="row">
                  <template v-if="row.item.unitDiscount > 0">
                    <span class="item-canceled-price">
                      {{ row.item.unitValue | currency }}
                    </span>
                    <br>
                    <span> {{ row.item.price | currency }} </span>
                  </template>
                  <template v-else>
                    {{ row.item.price | currency }}
                  </template>
                </template>
              </b-table> -->
            </b-col>
          </b-row>

          <b-row class="mb-3">
            <b-col>
              <p class="h5">
                {{ $t('Métodos de pagamento') }}:
              </p>
              <b-table
                class="bordered"
                striped
                show-empty
                :empty-text="$t('Nenhum pagamento registrado.')"
                :fields="paymentFields"
                :items="getPayments"
              >
                <template #cell(action)="row">
                  <e-grid-actions
                    :show-update="$can('UpdatePaymentMethod', 'Sale')"
                    :show-delete="isTefAndSaleCanceled(row.item)"
                    :delete-title="
                      isTefAndSaleCanceled(row.item) ? $t('PAY_BOX_SALE.TEF.CANCEL_TEF') : null
                    "
                    :buttons="buttonsPayments(row)"
                    @update="showUpdatePaymentModal(row.item)"
                    @delete="cancelSalePaymentTef(row.item)"
                    @print-receipt="onPrintReceipt(row.item)"
                  />
                </template>

                <template #cell(paymentMethod)="row">
                  {{ row.item.paymentMethod.name }}

                  <template v-if="row.item.cardBrand || row.item.nsu">
                    (
                    <template v-if="row.item.paymentMethodInterface">
                      {{ paymentMethodInterfaceLabel[row.item.paymentMethodInterface] || '-' }}
                    </template>

                    <template v-if="row.item.cardBrand">
                      {{ row.item.cardBrand || '-' }}
                    </template>

                    <template v-if="row.item.nsu">
                      <b>NSU: </b> {{ row.item.nsu || '-' }}
                    </template>
                    )
                  </template>
                </template>
              </b-table>
            </b-col>
          </b-row>

          <b-row class="mb-2">
            <b-col>
              <div class="line-total">
                <p class="h4">
                  Subtotal:
                </p>
                <p class="h4">
                  {{ getSaleSubtotal | currency }}
                </p>
              </div>

              <div class="line-total">
                <p class="h4">
                  Desconto:
                </p>
                <p class="h4">
                  {{ getDiscount | currency }}
                </p>
              </div>

              <div
                v-if="saleInfo.deliveryForm.deliveryTax > 0"
                class="line-total"
              >
                <p class="h4">
                  {{ $t('Taxa de conveniência') }}:
                </p>
                <p class="h4">
                  {{ saleInfo.deliveryForm.deliveryTax | currency }}
                </p>
              </div>

              <div
                v-if="saleInfo.comission > 0"
                class="line-total"
              >
                <p class="h4">
                  {{ $t('Comissão') }}:
                </p>
                <p class="h4">
                  {{ saleInfo.comission | currency }}
                </p>
              </div>

              <div class="line-total">
                <p class="h3">
                  {{ $t('Total da venda') }}:
                </p>
                <p class="h3">
                  {{ saleInfo.netValue | currency }}
                </p>
              </div>
            </b-col>
          </b-row>
        </b-card-actions>

        <b-card-actions
          v-if="isSaleCanceled"
          no-actions
          :title="$t('Dados do cancelamento')"
        >
          <b-row>
            <b-col md="3">
              <FormulateInput
                id="sidebar_info_sale-canceled_date"
                v-model="saleInfo.cancelationData.date"
                :label="$t('Data/Hora')"
                type="label"
                filter="datetime"
              />
            </b-col>
            <b-col md="5">
              <FormulateInput
                id="sidebar_info_sale-canceled_user"
                v-model="getCancelationUser"
                :label="$t('Usuário que cancelou')"
                type="label"
              />
            </b-col>

            <b-col md="12">
              <FormulateInput
                id="sidebar_info_sale-cancel_observation"
                v-model="saleInfo.cancelationData.reason"
                :label="$t('Observação')"
                type="label"
              />
            </b-col>
          </b-row>
        </b-card-actions>

        <b-card-actions
          v-if="showDeliveryData"
          no-actions
          :title="$t('Dados da entrega')"
        >
          <b-row>
            <b-col md="3">
              <FormulateInput
                id="sidebar_info_sale-delivery_date"
                v-model="saleInfo.deliveryForm.deliveryDate"
                :label="$t('Data/hora')"
                type="label"
                filter="datetime"
              />
            </b-col>
            <b-col md="6">
              <FormulateInput
                id="sidebar_info_sale-delivery_observation"
                v-model="saleInfo.deliveryForm.observation"
                :label="$t('Observação')"
                type="label"
              />
            </b-col>
            <b-col md="3">
              <FormulateInput
                id="sidebar_info_sale-delivery_status"
                :value="getDeliveryStatus ? getDeliveryStatus : '-'"
                :label="$t('Status')"
                type="label"
                :options="deliveryStatusOptions()"
                is-badge
              />
            </b-col>

            <b-col md="12">
              <FormulateInput
                id="sidebar_info_sale-delivery_address"
                :value="getDeliveryAddressFormated"
                :label="$t('Endereço')"
                type="label"
              />
            </b-col>
          </b-row>
        </b-card-actions>
      </template>

      <template #sidebar-footer>
        <div class="w-100">
          <div
            class="d-flex w-100 justify-content-end align-items-center"
            style="gap: 10px"
          >
            <template v-if="hasDeliveryStep">
              <e-button
                v-if="isDeliveryPending"
                id="sidebar_info_sale-btn_prepare_delivery"
                variant="primary"
                icon="box-seam"
                :text="$t('Preparar entrega')"
                :busy="saving"
                :disabled="fetching"
                @click="onPrepareDelivery"
              />
              <e-button
                v-if="isDeliveryPreparing"
                id="sidebar_info_sale-btn_start_delivery"
                variant="primary"
                icon="truck"
                :text="$t('Realizar entrega')"
                :busy="saving"
                :disabled="fetching"
                @click="onStartDelivery"
              />
              <e-button
                v-if="isDeliveryInProgress"
                id="sidebar_info_sale-btn_complete_delivery"
                variant="primary"
                icon="check-square"
                :text="$t('Concluir entrega')"
                :busy="saving"
                :disabled="fetching"
                @click="onFinishDelivery"
              />
            </template>

            <e-button
              v-if="showCancelButton"
              id="sidebar_info_sale-btn_cancel"
              variant="danger"
              icon="x-circle"
              :text="$t('Cancelar venda')"
              :busy="saving"
              :disabled="fetching"
              @click="onCancel"
            />
            <e-button
              id="sidebar_info_sale-btn_print_coupon"
              variant="primary"
              icon="printer-fill"
              :text="$t('Imprimir')"
              :busy="saving"
              :disabled="fetching"
              @click="onPrintInvoiceCoupon"
            />
            <e-button
              v-if="isPendingLayout"
              id="sidebar_info_sale-btn_finish"
              variant="primary"
              icon="check-circle"
              :text="btnFinishLabel"
              :busy="saving"
              :disabled="fetching"
              @click="onSendSaleToPayBox"
            />
          </div>
        </div>
      </template>
    </e-sidebar>

    <e-modal-custom-form
      ref="modalCancelForm"
      title="Dados para cancelar a venda"
    >
      <b-row>
        <b-col
          md="12"
          class="d-flex flex-column"
        >
          <FormulateInput
            id="sidebar_info_sale-modal_cancel-reason"
            v-model="cancelForm.cancelationReason"
            name="reason"
            class="w-100 required"
            type="textarea"
            validation="required|min:15,length"
            rows="4"
            :label="$t('Motivo do cancelamento')"
          />
        </b-col>
      </b-row>
    </e-modal-custom-form>

    <modal-update-sale-payment
      ref="modalSalePayment"
      @after-confirm="onUpdateSaleInfo"
    />

    <bar-tab-consumption-info-sidebar
      ref="barTabConsumptionSidebar"
      is-history-view
      read-only
      @show-sale="onHideConsumptionSidebar"
    />
  </div>
</template>

<script>
import { BRow, BCol, BTable } from 'bootstrap-vue'
import { mapActions, mapState, mapGetters } from 'vuex'
import {
  address,
  formulateHelper,
  statusTypes,
  loadingOverlay,
  productDomains,
  paymentTypes,
  payBoxPayments,
  payBoxSale,
  invoiceDomains,
  saleDomain,
} from '@/mixins'
import delegablePermissions from '@/utils/delegable-permissions'
import BCardActions from '@/@core/components/b-card-actions/BCardActions.vue'
import EButton from '@/views/components/EButton.vue'
import ESidebar from '@/views/components/ESidebar.vue'
import EModalCustomForm from '@/views/components/EModalCustomForm.vue'
import EStatusBadge from '@/views/components/EStatusBadge.vue'
import EGridActions from '@/views/components/EGridActions.vue'
import { getAppVersions } from '@/utils/app-utils'
import { hasTefCardActive, isTefCard, tefCardActive } from '@/store/utils/paybox/paybox-payments'
import EPayBoxSaleItemTable from '@/views/components/tables/EPayBoxSaleItemTable.vue'
import ModalUpdateSalePayment from './components/ModalUpdateSalePayment.vue'
import BarTabConsumptionInfoSidebar from '../pay-box-bar/BarTabConsumptionInfoSidebar.vue'

const getInitialForm = () => ({
  cancelationReason: '',
})

export default {
  name: 'SaleInfoSidebar',
  components: {
    BRow,
    BCol,
    BTable,
    BCardActions,
    EButton,
    ESidebar,
    EModalCustomForm,
    EStatusBadge,
    ModalUpdateSalePayment,
    EGridActions,
    BarTabConsumptionInfoSidebar,
    EPayBoxSaleItemTable,
  },

  mixins: [
    formulateHelper,
    statusTypes,
    loadingOverlay,
    paymentTypes,
    payBoxPayments,
    invoiceDomains,
    saleDomain,
  ],

  props: {
    isPendingLayout: {
      type: Boolean,
      required: true,
    },
    // flag pra dizer que foi instanciado no Bar
    isFromBar: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      showSidebar: false,
      fetching: false,
      saving: false,
      delegateUserData: null,
      cancelForm: getInitialForm(),
      saleInfo: payBoxSale.data().getInitialPayBoxSale(),
      appVersions: JSON.stringify(getAppVersions()),
    }
  },

  computed: {
    ...mapState('app', ['modalAuthRef']),
    // ...mapState('pages/pdv', ['modalAuthRef']),
    ...mapGetters('pages/pdv/payBoxConfiguration', [
      'thermalPrinterAgentConfigPayload',
      'invoiceAgentConfigPayload',
      'currentPayboxConfiguration',
    ]),

    getDeliveryStatus() {
      return this.saleInfo?.deliveryForm?.deliveryStatus
    },
    getCustomerName() {
      return this.saleInfo?.clientForm?.name || this.$t('Não informado')
    },
    getCancelationUser() {
      return this.saleInfo?.cancelationData?.user?.name || this.$t('Não informado')
    },
    getDeliveryAddressFormated() {
      return address.methods.formatAddress(
        this.saleInfo?.deliveryForm?.address || address.data().address
      )
    },
    getBarTabConsumptionId() {
      return this.saleInfo?.barTabConsumption?.id
    },
    getItems() {
      if (!this.saleInfo.items) return []
      return this.saleInfo.items
    },
    getPayments() {
      return this.saleInfo?.paymentForm?.receipts?.filter(p => !p.canceled) || []
    },

    hasPendingValueToPay() {
      return this.saleInfo.pendingPaymentValue > 0
    },

    getStatusLabel() {
      const status = this.saleStatusLabel[this.saleInfo.status]
      if (status) return status
      return ''
    },
    getDiscount() {
      const { paymentForm, deliveryForm, items } = this.saleInfo
      const productTotalDiscountValue = payBoxSale.methods.getTotalItemsDiscount(items ?? [])
      return (
        productTotalDiscountValue +
        (paymentForm?.discount || 0) +
        (deliveryForm?.deliveryTaxDiscountValue || 0)
      )
    },
    getSaleSubtotal() {
      return payBoxSale.methods.getSubtotalSaleWithoutDiscount(this.saleInfo.items || [])
    },

    showCancelButton() {
      if (this.isSaleCanceled) return false

      if (this.isPendingLayout) {
        if (this.isConsigned) return false
        return !this.isSaleCompleted
      }

      return true
    },

    showPaymentActions() {
      return !this.isPendingLayout
    },

    hasReclaimItems() {
      const { RENT, LENDING } = productDomains.computed.productClassificationsEnum()
      return this.getItems.some(i => i.classification === LENDING || i.classification === RENT)
    },

    hasDeliveryStep() {
      return (
        this.isPendingLayout &&
        (this.saleInfo.isDelivery || this.saleInfo.isConsigned || this.hasReclaimItems) &&
        !this.isFinishedDelivery
      )
    },

    isSaleCompleted() {
      return this.saleInfo.status === statusTypes.computed.saleStatusEnum().COMPLETED
    },
    isSaleInProgress() {
      return this.saleInfo.status === statusTypes.computed.saleStatusEnum().IN_PROGRESS
    },
    isSaleCanceled() {
      return this.saleInfo.status === statusTypes.computed.saleStatusEnum().CANCELED
    },

    isDeliveryPending() {
      return this.getDeliveryStatus === statusTypes.computed.deliveryStatusEnum().PENDING
    },
    isDeliveryInProgress() {
      return this.getDeliveryStatus === statusTypes.computed.deliveryStatusEnum().IN_PROGRESS
    },
    isDeliveryCompleted() {
      return this.getDeliveryStatus === statusTypes.computed.deliveryStatusEnum().COMPLETED
    },
    isDeliveryPreparing() {
      return this.getDeliveryStatus === statusTypes.computed.deliveryStatusEnum().PREPARING
    },
    isDeliveryCanceled() {
      return this.getDeliveryStatus === statusTypes.computed.deliveryStatusEnum().CANCELED
    },
    isFinishedDelivery() {
      return this.isDeliveryCompleted || this.isDeliveryCanceled
    },

    isConsigned() {
      return this.saleInfo?.isConsigned
    },

    showDeliveryData() {
      return this.saleInfo.isDelivery || this.saleInfo.isConsigned || this.hasReclaimItems
    },
    buttonsPayments() {
      return row =>
        [
          {
            icon: 'printer-fill',
            event: 'print-receipt',
            title: this.$t('PAY_BOX_SALE.TEF.PRINT_TEF'),
            hide:
              row.item.paymentMethodInterface !== this.paymentMethodInterfaceEnum.TEF ||
              !row.item.tefPayment?.couponCustomer,
          },
        ].filter(btn => !btn.hide)
    },

    paymentFields() {
      return [
        {
          label: this.$t('Ações'),
          key: 'action',
          thClass: 'text-center',
          tdClass: 'text-center',
          thStyle: { width: '100px' },
          hide: !this.showPaymentActions,
        },
        {
          label: this.$t('Tipo'),
          key: 'paymentMethod',
          thClass: 'text-center',
          tdClass: 'text-left',
        },
        {
          label: this.$t('Valor'),
          key: 'value',
          thClass: 'text-center',
          tdClass: 'text-right',
          thStyle: { width: '120px' },
          formatter: value => this.$options.filters.currency(value),
        },
      ].filter(a => !a.hide)
    },

    btnFinishLabel() {
      let label = this.$t('Finalizar')

      if (this.isConsigned) {
        if (this.hasPendingValueToPay) label = this.$t('Registrar pagamento')
      }

      return label
    },

    consumptionIdLabelIcons() {
      if (!this.getBarTabConsumptionId) return []

      return [
        {
          eventName: 'link',
          icon: 'box-arrow-up-right',
          clazz: '',
          variant: '',
          title: this.$t('Mostrar dados do consumo'),
        },
      ]
    },
  },

  methods: {
    ...mapActions('pages/pdv/manageSales', ['fetchSaleById']),
    ...mapActions('pages/pdv/payBox', ['loadSaleInPayBox', 'fetchPayBoxSale']),
    ...mapActions('pages/sale/order', ['printOrder', 'printOrderExpedition']),

    hide() {
      this.cancelForm = getInitialForm()
      this.delegateUserData = null
      this.saleInfo = payBoxSale.data().getInitialPayBoxSale()
      this.showSidebar = false
    },

    async getData(saleId) {
      try {
        this.fetching = true
        this.saleInfo = await this.fetchPayBoxSale({ saleId, readOnly: true })
      } finally {
        this.fetching = false
      }
    },

    async show(saleId) {
      try {
        this.resetCancelForm()
        this.showSidebar = true
        await this.getData(saleId)
      } catch (error) {
        this.showError({ error })
        this.hide()
      }
    },

    async onUpdateSaleInfo(saleId) {
      try {
        this.fetching = true
        await this.getData(saleId || this.saleInfo.id)
      } catch (error) {
        this.showError({ error })
      }
    },

    resetCancelForm() {
      this.cancelForm = getInitialForm()
    },

    async cancelSale() {
      let resultCancel = {}
      if (this.isSaleInProgress) {
        await this.cancelPendingSale()
      } else if (this.saleInfo?.invoice) {
        resultCancel = await this.cancelInvoiceCreateSale()
      } else {
        const body = {
          id: this.saleInfo.id,
          cancelationReason: this.cancelForm.cancelationReason,
          applicationVersion: this.appVersions,
        }

        await this.$http.put('/api/sales/pay-box-sale/cancel', body, {
          headers: {
            'delegate-token': this.delegateUserData?.token,
          },
        })
      }

      await this.cancelActiveTefCardPayments()

      return resultCancel
    },

    async cancelSalePaymentTef(receipt) {
      const tefPaymentCancelation = await this.cancelTefPayment(receipt)
      if (tefPaymentCancelation) {
        await this.$http.patch(
          `/api/sales/pay-box-sale/cancel-tef-payment/${receipt.id}`,
          tefPaymentCancelation
        )
      }
    },

    async cancelActiveTefCardPayments() {
      const payments = this.getPayments
      if (hasTefCardActive(payments)) {
        const confirmed = await this.confirm({
          title: this.$t('Cancelamento TEF'),
          text: this.$t('PAY_BOX_SALE.TEF.CANCEL_SALE_WITH_TEF'),
          allowOutsideClick: false,
          allowEscapeKey: false,
        })
        if (!confirmed) return

        const activePayments = tefCardActive(payments)

        // eslint-disable-next-line no-restricted-syntax
        for (const receipt of activePayments) {
          // eslint-disable-next-line no-await-in-loop
          await this.cancelSalePaymentTef(receipt)
        }
      }
    },

    async cancelInvoiceCreateSale() {
      const { store } = this.currentPayboxConfiguration
      let invoiceLog
      const invoice = this.saleInfo?.invoice
      if (invoice) {
        if (invoice.model === 'SAT') {
          const invoiceToCancel = {
            store,
            payBoxUuid: this.saleInfo?.uuid,
            payBoxId: this.saleInfo?.payBoxId,
            issueDate: invoice.issueDate,
            accessKey: invoice.satDetail.cfeAccessKey,
            fileName: invoice.satDetail.cfeXmlPath,
            serialNumber: parseInt(invoice.serialNumber, 0),
            invoiceNumber: invoice?.invoiceNumber,
            origin: this.saleInfo?.origin,
          }

          const data = {
            request: JSON.stringify(invoiceToCancel),
            ...this.invoiceAgentConfigPayload,
          }

          const resultCancel = await window.electronAPI.invoice.satCancel({ ...data })
          if (resultCancel.success === true || this.saleInfo?.origin === 'App') {
            const payBoxSaleSatCancelDto = {
              serialNumberCancelled: resultCancel.serialNumber,
              cfeNumberCancelled: resultCancel.cfeNumber,
              cfeAccessKeyCancelled: resultCancel.accessKey,
              returnCodeCancelled: resultCancel.code,
              returnMessageCancelled: resultCancel.message,
              cfeXmlPathCancelled: resultCancel.fileName,
            }

            const body = {
              id: this.saleInfo.id,
              cancelationReason: this.cancelForm.cancelationReason,
              payBoxSaleSatCancel: resultCancel.success === true ? payBoxSaleSatCancelDto : null,
              applicationVersion: this.appVersions,
            }

            await this.$http.put('/api/sales/pay-box-sale/cancel', body, {
              headers: {
                'delegate-token': this.delegateUserData?.token,
              },
            })

            invoiceLog = {
              model: 'SAT',
              invoiceNumber: resultCancel.cfeNumber,
              xmlPath: resultCancel.fileName,
              returnMessage: resultCancel.message,
              displayMessage: resultCancel.displayMessage,
              returnCode: resultCancel.code,
              accessKey: resultCancel.accessKey,
            }
          }
        } else if (invoice.model === 'NFCE' || invoice.model === 'NFE') {
          const invoiceToCancel = {
            payBoxUuid: this.saleInfo?.uuid,
            payBoxId: this.saleInfo?.payBoxId,
            store,
            accessKey: invoice?.nfeDetail?.accessKey,
            justification: this.cancelForm.cancelationReason,
            serialNumber: invoice?.serialNumber,
            invoiceNumber: invoice?.invoiceNumber,
            origin: this.saleInfo?.origin,
          }

          const data = {
            request: JSON.stringify(invoiceToCancel),
            ...this.invoiceAgentConfigPayload,
          }

          const resultCancel = await window.electronAPI.invoice.nfceCancel({ ...data })

          if (
            resultCancel.status === 'SUCCESS_FINISHED' ||
            (resultCancel.status === 'ERROR' && this.saleInfo?.origin === 'App')
          ) {
            const body = {
              id: this.saleInfo.id,
              cancelationReason: this.cancelForm.cancelationReason,
              payBoxSaleNFCeCancel:
                resultCancel.status === 'SUCCESS_FINISHED' ? resultCancel : null,
              applicationVersion: this.appVersions,
            }

            await this.$http.put('/api/sales/pay-box-sale/cancel', body, {
              headers: {
                'delegate-token': this.delegateUserData?.token,
              },
            })

            invoiceLog = {
              model: invoice.model,
              invoiceNumber: invoice?.invoiceNumber,
              xmlPath: resultCancel.xml,
              returnMessage: resultCancel.returnMessage,
              displayMessage: resultCancel.displayMessage,
              returnCode: resultCancel.returnCode,
              accessKey: resultCancel.accessKey,
            }
          }
        }

        if (invoiceLog?.invoiceNumber) {
          invoiceLog = {
            payBoxId: this.saleInfo?.payBoxId,
            payBoxUuid: this.saleInfo?.uuid,
            storeId: store?.id,
            status: 'CANCELED',
            eventType: 'CANCEL_INVOICE',
            origin: 'PDV',
            serialNumber: invoice?.serialNumber,
            ...invoiceLog,
          }
          await this.$http.post('/api/sales/pay-box/invoice-log', invoiceLog)

          return {
            displayMessage: invoiceLog.displayMessage,
          }
        }
      }

      return {}
    },

    async cancelPendingSale() {
      const body = {
        id: this.saleInfo.id,
        cancelationReason: this.cancelForm.cancelationReason,
        applicationVersion: this.appVersions,
      }

      await this.$http.put('/api/sales/orders/cancel', body, {
        headers: {
          'delegate-token': this.delegateUserData?.token,
        },
      })
    },

    isTefAndSaleCanceled(salePayment) {
      return isTefCard(salePayment) && this.isSaleCanceled
    },
    showUpdatePaymentModal(salePayment) {
      this.$refs.modalSalePayment.show(salePayment)
    },
    async onPrintReceipt(receipt) {
      const { tefPayment, tefPaymentCancelation } = receipt
      if (tefPayment || tefPaymentCancelation) {
        const tefPayload = receipt.canceled ? tefPaymentCancelation : tefPayment

        try {
          await window.electronAPI.print({
            ...this.thermalPrinterAgentConfigPayload,
            request: {
              openCashDrawer: false,
              printTexts: [
                {
                  printText: tefPayload.couponStore,
                  template: 'PAYMENT_GETNET',
                },
                {
                  printText: tefPayload.couponCustomer,
                  template: 'PAYMENT_GETNET',
                },
              ],
            },
          })
        } catch (error) {
          console.error('error on print tef', error)
        }
      }
    },

    async onCancel() {
      if (this.saleInfo.reason === this.saleReasonEnum.CHOPP_GIFT_CARD_CONVERSION) {
        this.showWarning({ message: this.$t("PAY_BOX_SALE.CANCEL.SALE_HAVE_GIFT_CARD_CONVERSION") })
        return
      }

      try {
        const confirmed = await this.confirm({
          text: this.$t('Deseja realmente cancelar esta venda?'),
        })
        if (!confirmed) return

        this.delegateUserData = await this.modalAuthRef.auth(delegablePermissions.PDV_CANCEL)

        await this.$refs.modalCancelForm.show(() => {})
      } catch (error) {
        this.resetCancelForm()
        return
      }

      try {
        this.saving = true
        await new Promise(resolve => setTimeout(() => resolve(), 1000))

        const body = { id: this.saleInfo.id }
        await this.$http.post(`/api/sales/pay-box-sale/validate-sale-in-cash-cancel`, body, {
          headers: {
            'delegate-token': this.delegateUserData?.token,
          },
        })

        const resultCancel = await this.cancelSale()

        this.$emit('get-sale-data')

        this.showSuccess({
          title: this.$t('Venda cancelada'),
          message: resultCancel.displayMessage,
        })
        this.hide()
      } catch (error) {
        console.log('erro: ', error)
        this.showError({ error })
      } finally {
        this.saving = false
        this.resetCancelForm()
      }
    },

    async onPrintInvoiceCoupon() {
      try {
        const saleToPrintData = await this.fetchSaleById(this.saleInfo.id)

        const validToPrint = this.mxValidatePrintForOnlineSale(saleToPrintData)
        if (!validToPrint.valid) {
          this.showWarning({ message: validToPrint.message })
          return
        }

        if (this.hasDeliveryStep && !this.isDeliveryPreparing) {
          const confirmUpdateDeliveryStatus = await this.confirm({
            title: this.$t('Alterar status do delivery para "Preparando"?'),
            text: this.$t(' '),
            icon: '',
          })
          if (confirmUpdateDeliveryStatus) {
            this.showLoadingOverlay(this.$t('Aguarde um momento'))
            await this.onPrepareDelivery()
          }
        }

        if (this.currentPayboxConfiguration.expeditionThermalPrinter) {
          this.hideLoadingOverlay()

          const choice = await this.confirmChoice({
            title: this.$t('Realizar impressão da Expedição ou impressão do cupom?'),
            text: this.$t(' '),
            icon: '',
            confirmButtonText: this.$t('Cupom'),
            denyButtonText: this.$t('Expedição'),
            cancelButtonText: this.$t('Voltar'),
          })

          if (choice.isDenied) {
            this.showLoadingOverlay(this.$t('Imprimindo recibo para retirada na expedição'))
            try {
              this.busy = true
              await this.printOrderExpedition({ orderPrint: saleToPrintData })
            } catch (err) {
              console.error('print expedition:', err)
            } finally {
              this.busy = false
            }
          }

          if (choice.isDismissed) return
        }

        this.showLoadingOverlay(this.$t('Imprimindo cupom'))

        try {
          this.busy = true
          await this.printOrder({ orderPrint: saleToPrintData, template: 'SALE_ORDER' })
        } catch (err) {
          console.error('print:', err)
        } finally {
          this.busy = false
        }
      } catch (error) {
        this.showError({ error })
      } finally {
        this.hideLoadingOverlay()
      }
    },

    async changeDeliveryStatus(type) {
      const types = {
        prepare: 'prepare',
        start: 'start',
        finish: 'finish',
      }

      if (this.isConsigned && this.hasDeliveryStep && this.getPayments.length === 0) {
        this.showError({
          title: this.$t('Não foi possível alterar o status'),
          message: this.$t('Registre um pagamento antes para realizar esta ação.'),
        })
        return
      }

      if (types[type]) {
        try {
          this.saving = true
          const { id } = this.saleInfo

          await this.$http.patch(`/api/sales/pay-box-sale/delivery/${types[type]}/${id}`)
          this.showSuccess({ message: this.$t('Status do delivery alterado') })
          this.$emit('get-sale-data')

          if (types[type] !== types.finish) {
            this.fetching = true
            await this.getData(id)
          } else {
            this.hide()
          }
        } catch (error) {
          this.showError({ error })
        } finally {
          this.fetching = false
          this.saving = false
        }
      }
    },

    async onPrepareDelivery() {
      await this.changeDeliveryStatus('prepare')
    },

    async onStartDelivery() {
      await this.changeDeliveryStatus('start')
    },
    async onFinishDelivery() {
      await this.changeDeliveryStatus('finish')
    },

    async onSendSaleToPayBox() {
      try {
        if (this.isSaleCompleted) {
          this.showWarning({
            title: this.$t('Esta venda já foi finalizada'),
          })
          return
        }

        if (this.isConsigned) {
          if (!this.hasPendingValueToPay && !this.isDeliveryCompleted) {
            this.showWarning({
              title: this.$t('Entrega deve estar concluída'),
              message: this.$t('Para finalizar este consignado conclua a entrega da venda.'),
            })
            return
          }
        }

        this.showLoadingOverlay(this.$t('Aguarde um momento, estamos buscando os dados da venda'))
        await this.loadSaleInPayBox({ saleId: this.saleInfo.id })
        this.$router.push({ name: 'pdv-pay-box' })
      } catch (error) {
        this.hideLoadingOverlay()
        this.showError({ error })
      }
    },

    showBarConsumption() {
      if (this.isFromBar) {
        this.hide()
        return
      }
      this.$refs.barTabConsumptionSidebar.show(this.getBarTabConsumptionId)
    },
    onHideConsumptionSidebar() {
      this.$refs.barTabConsumptionSidebar.hide()
    },
  },
}
</script>

<style lang="scss" scoped>
.line-total {
  width: 100%;
  display: flex;
  align-items: baseline;
  justify-content: space-between;

  & + .line-total {
    padding-top: 5px;
    border-top: 1px solid var(--dark);
  }
}
.item-canceled-price {
  text-decoration: line-through;
  color: var(--danger);
}
</style>
