import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import axios from 'axios'
import isElectron from 'is-electron'
import { isJson } from '@/utils/object-utils'

// We format error message so it shows properly in electron logs, currently some objects it fails to send to electron process
function parseErrorObject(error) {
  try {
    if (!error) return null

    if (!axios.isAxiosError(error)) {
      return isElectron() ? JSON.stringify(error) : error
    }
    const echopeHeaders = {}
    if (error.config?.headers) {
      const { headers } = error.config
      Object.keys(headers).forEach(key => {
        if (key.toLowerCase().startsWith('echope')) {
          echopeHeaders[key] = headers[key]
        }
      })
    }

    const errorFormatted = {
      name: error.name,
      code: error.code,
      message: error.message,
      method: error.config?.method,
      url: error.config?.url,
      echopeHeaders,
      response: error.response?.response || error?.response?.responseText,
    }

    return isElectron() ? JSON.stringify(errorFormatted) : errorFormatted
  } catch (err) {
    /* eslint-disable no-console */
    console.error(err)
    return isElectron() ? JSON.stringify(error) : error
  }
}

const alerts = {
  components: {
    ToastificationContent,
  },
  methods: {
    showSuccess({ title, message, icon }) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: title || this.$t('Sucesso'),
            icon: icon || 'CheckCircleIcon',
            text: message,
            variant: 'success',
          },
        },
        {
          position: 'top-right',
        }
      )
    },
    showError({ title, error, message, icon, action }) {
      let { title: localTitle, message: localMessage } = { title, message }

      // TODO substituir ifs que alteram o errorMessage por -> const { message } = this.getErrorMessageFromResponse(error)
      let logId =
        error && (error.logId || error?.response?.data?.logId)
          ? error.logId || error?.response?.data?.logId
          : ''
      if (error && (error.message === 'Forbidden content' || error.response?.status === 403)) {
        logId = ''
        localTitle = this.$t('Acesso negado')
        localMessage = this.$t('Você não possui acesso a esta funcionalidade')
      }
      let errorMessage

      if (error && typeof error === 'string') {
        errorMessage = this.$t(error)
      }

      if (error?.response?.data) {
        if (typeof error?.response?.data === 'string') {
          errorMessage = this.$t(error.response.data)
        } else if (error?.response.data?.status === 500) {
          errorMessage = `${this.$t(
            'Ocorreu um erro na sua solicitação. Entre em contato com a equipe de suporte e informe o valor'
          )} ${logId}`
        } else {
          const keys = Object.keys(error.response.data)
          const firstMessage = error.response.data[keys[0]] ? error.response.data[keys[0]][0] : ''
          if (
            keys.length === 1 &&
            typeof firstMessage === 'string' &&
            firstMessage.includes('|||[')
          ) {
            const [mainError, strItems] = firstMessage.split('|||')
            const itemsList = JSON.parse(strItems)
            const itemsMessage = itemsList
              .map(i => `- ${this.$t(`${mainError}|||`, i)} <br>`)
              .join('')
            errorMessage = `${this.$tc(mainError, itemsList.length)} <br> ${itemsMessage}`
          } else if (
            keys.length === 1 &&
            typeof firstMessage === 'string' &&
            firstMessage.includes('|')
          ) {
            const [mainError, strValue] = firstMessage.split('|')
            errorMessage = `${this.$t(mainError, { text: strValue })}`
          } else if (isJson(firstMessage)) {
            const parsed = JSON.parse(firstMessage)
            let { args } = parsed
            const haveArgs = args
            const { request } = parsed
            if (!haveArgs) {
              args = request
            }

            let listLength = 1
            Object.entries(args).forEach(([key, value]) => {
              if (Array.isArray(value)) {
                const item = value.map(i => `- ${this.$t(i)} <br>`).join('')
                listLength = value.length
                args[key] = `<br> ${item}`
              } else if (!haveArgs) {
                args[key] = `- ${this.$t(value)} <br>`
              }
            })

            if (!haveArgs) {
              errorMessage = args.toString()
            } else {
              errorMessage = `${this.$tc(parsed.message, listLength, args)}`
            }
          } else {
            const errorArray = []
            keys.forEach(key => {
              const infoIndex = key?.match(/\[\d+\]/) ? `${key?.match(/\[\d+\]/)[0]} ` : ''
              const errList = error.response.data[key]
              if (Array.isArray(errList)) {
                errList.forEach(err => {
                  errorArray.push(`- ${infoIndex}${this.$t(err)}`)
                })
              } else if (errList?.errors?.length > 0) {
                errorArray.push(
                  `- ${infoIndex}${this.$t(errList.errors.map(e => e.errorMessage).join(', '))}`
                )
              }
            })
            errorMessage = errorArray.join('<br>')
          }
        }
      }
      if (!errorMessage) {
        errorMessage = `${
          localMessage ||
          (error ? this.$t(error.message) : undefined) ||
          this.$t('Erro desconhecido')
        }`
      }

      /* eslint-disable no-console */
      console.error('showError', title, errorMessage, parseErrorObject(error))

      // TODO: remover depois de identificar a causa raiz do problema e correção
      if (
        (errorMessage ?? '').includes('The PaymentMethodId field is required') ||
        (errorMessage ?? '').includes("'Nsu' is required")
      ) {
        try {
          window.electronAPI.incident.createIncident({
            title: errorMessage,
            tags: ['error-type=monitoring'],
            payload: JSON.stringify({ request: error.response?.config?.data, error: errorMessage }),
          })
        } catch (e) {
          console.error('error on create incident', e)
        }
      }

      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: localTitle || this.$t('Ocorreu um erro'),
            icon: icon || 'AlertCircleIcon',
            text: action ? `${action}: ${errorMessage}` : errorMessage,
            variant: 'danger',
          },
        },
        {
          position: 'top-right',
        }
      )
    },
    showGenericError({ title, error } = {}) {
      /* eslint-disable no-console */
      console.error(error)
      const message = this.$t('Desculpe, ocorreu um erro na sua solicitação')
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: title || this.$t('Ocorreu um erro'),
            icon: 'AlertCircleIcon',
            text: message,
            variant: 'danger',
          },
        },
        {
          position: 'top-right',
        }
      )
    },
    showWarning({ title, message, icon }) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: title || this.$t('Aviso'),
            icon: icon || 'AlertCircleIcon',
            text: message,
            variant: 'warning',
          },
        },
        {
          position: 'top-right',
        }
      )
    },
    showInvalidDataMessage({ title, message, icon } = {}) {
      const { title: localTitle, message: localMessage } = { title, message }
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: localTitle || this.$t('Dados inválidos'),
            icon: icon || 'AlertCircleIcon',
            text: `${localMessage || this.$t('Dados inválidos, verifique.')}`,
            variant: 'danger',
          },
        },
        {
          position: 'top-right',
        }
      )
    },
    showInformation({
      title,
      text,
      icon, // success, warning, info, question, error
      confirmButtonText,
      cancelButtonText,
      showCancelButton = false,
      showConfirmButton = true,
      allowOutsideClick = true,
    } = {}) {
      return new Promise(resolve => {
        this.$swal({
          title: title || `${this.$t('Informação')}`,
          text,
          icon: icon || 'info',
          showCancelButton,
          showConfirmButton,
          confirmButtonText: confirmButtonText || this.$t('Ok'),
          cancelButtonText: cancelButtonText || this.$t('Cancelar'),
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
          allowOutsideClick,
        }).then(result => {
          resolve(result.value)
        })
      })
    },
    showAtmAlert({
      error,
      title,
      text,
      icon, // success, warning, info, question, error
      confirmButtonText,
      cancelButtonText,
      showCancelButton = false,
      showConfirmButton = true,
      allowOutsideClick = false,
      showDenyButton = false,
      denyButtonText = '',
      timer = 5000,
      timerProgressBar = true,
    } = {}) {
      const { message, title: localTitle } = this.getErrorMessageFromResponse(error)

      return new Promise(resolve => {
        this.$swal({
          title: localTitle || title,
          text: message || text,
          icon: icon || 'info',
          showCancelButton,
          showConfirmButton,
          showDenyButton,
          confirmButtonText: confirmButtonText || this.$t('Ok'),
          cancelButtonText: cancelButtonText || this.$t('Cancelar'),
          denyButtonText,
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
          allowOutsideClick,
          timer,
          timerProgressBar,
        }).then(result => {
          resolve(result.value)
        })
      })
    },
    confirm({
      title,
      text,
      icon,
      confirmButtonText,
      cancelButtonText,
      showCancelButton = true,
      showConfirmButton = true,
      allowEnterKey = true,
      allowOutsideClick = true,
      allowEscapeKey = true,
      focusCancel = false,
    } = {}) {
      return new Promise(resolve => {
        this.$swal({
          title: title || `${this.$t('Confirma')}?`,
          text: text || this.$t('Tem certeza que deseja confirmar esta ação?'),
          icon: icon || 'warning',
          showCancelButton,
          showConfirmButton,
          allowEnterKey,
          allowOutsideClick,
          allowEscapeKey,
          focusCancel,
          confirmButtonText: confirmButtonText || this.$t('Sim'),
          cancelButtonText: cancelButtonText || this.$t('Não'),
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
        }).then(result => {
          resolve(result.value)
        })
      })
    },
    /**
     * @returns {Promise<Object>} { isConfirmed: boolean, isDenied: boolean, isDismissed: boolean, value: String|Null }
     */
    confirmChoice({
      title,
      text,
      icon,
      confirmButtonText,
      cancelButtonText,
      denyButtonText,
      showCancelButton = true,
      showConfirmButton = true,
      showDenyButton = true,
      allowEnterKey = true,
      allowOutsideClick = true,
      allowEscapeKey = true,
      focusCancel = false,
    } = {}) {
      return new Promise(resolve => {
        this.$swal({
          title: title || `${this.$t('Confirma')}?`,
          text: text || this.$t('Tem certeza que deseja confirmar esta ação?'),
          icon: icon || 'warning',
          showCancelButton,
          showConfirmButton,
          showDenyButton,
          allowEnterKey,
          allowOutsideClick,
          allowEscapeKey,
          focusCancel,
          confirmButtonText: confirmButtonText || this.$t('Sim'),
          cancelButtonText: cancelButtonText || this.$t('Não'),
          denyButtonText: denyButtonText || this.$t('Talvez'),
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
            denyButton: 'btn btn-outline-primary ml-1',
          },
          buttonsStyling: false,
        }).then(result => {
          resolve(result)
        })
      })
    },

    confirmHtml({
      title,
      html,
      icon,
      confirmButtonText,
      cancelButtonText,
      showCancelButton = true,
      focusCancel = false,
    } = {}) {
      return new Promise(resolve => {
        this.$swal({
          title: title || `${this.$t('Confirma')}?`,
          html: html || this.$t('Tem certeza que deseja confirmar esta ação?'),
          icon: icon || 'warning',
          showCancelButton,
          focusCancel,
          confirmButtonText: confirmButtonText || this.$t('Sim'),
          cancelButtonText: cancelButtonText || this.$t('Não'),
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
        }).then(result => {
          resolve(result.value)
        })
      })
    },

    getErrorMessageFromResponse(error, messageDefault = '', titleDefault = '') {
      if (!error) {
        return {
          logId: '',
          title: '',
          message: '',
        }
      }

      let localTitle = titleDefault
      let localMessage = messageDefault

      let logId =
        error && (error.logId || error?.response?.data?.logId)
          ? error.logId || error?.response?.data?.logId
          : ''
      if (error && (error.message === 'Forbidden content' || error.response?.status === 403)) {
        logId = ''
        localTitle = this.$t('Acesso negado')
        localMessage = this.$t('Você não possui acesso a esta funcionalidade')
      }
      let errorMessage

      if (error && typeof error === 'string') {
        errorMessage = this.$t(error)
      }

      if (error?.response?.data) {
        if (typeof error?.response?.data === 'string') {
          errorMessage = this.$t(error.response.data)
        } else if (error?.response.data?.status === 500) {
          errorMessage = `${this.$t(
            'Ocorreu um erro na sua solicitação. Entre em contato com a equipe de suporte e informe o valor'
          )} ${logId}`
        } else {
          const keys = Object.keys(error.response.data)
          const firstMessage = error.response.data[keys[0]] ? error.response.data[keys[0]][0] : ''
          if (
            keys.length === 1 &&
            typeof firstMessage === 'string' &&
            firstMessage.includes('|||[')
          ) {
            const [mainError, strItems] = firstMessage.split('|||')
            const itemsList = JSON.parse(strItems)
            const itemsMessage = itemsList
              .map(i => `- ${this.$t(`${mainError}|||`, i)} <br>`)
              .join('')
            errorMessage = `${this.$tc(mainError, itemsList.length)} <br> ${itemsMessage}`
          } else if (
            keys.length === 1 &&
            typeof firstMessage === 'string' &&
            firstMessage.includes('|')
          ) {
            const [mainError, strValue] = firstMessage.split('|')
            errorMessage = `${this.$t(mainError, { text: strValue })}`
          } else if (isJson(firstMessage)) {
            const parsed = JSON.parse(firstMessage)
            const { args } = parsed

            let listLength = 1
            Object.entries(args).forEach(([key, value]) => {
              if (Array.isArray(value)) {
                const item = value.map(i => `- ${this.$t(i)} <br>`).join('')
                listLength = value.length
                args[key] = `<br> ${item}`
              }
            })

            errorMessage = `${this.$tc(parsed.message, listLength, args)}`
          } else {
            const errorArray = []
            keys.forEach(key => {
              const infoIndex = key?.match(/\[\d+\]/) ? `${key?.match(/\[\d+\]/)[0]} ` : ''
              const errList = error.response.data[key]
              if (Array.isArray(errList)) {
                errorArray.push(`- ${infoIndex}${this.$t(errList.join(', '))}`)
              } else if (errList?.errors?.length > 0) {
                errorArray.push(
                  `- ${infoIndex}${this.$t(errList.errors.map(e => e.errorMessage).join(', '))}`
                )
              }
            })
            errorMessage = errorArray.join('<br>')
          }
        }
      }

      if (!errorMessage) {
        errorMessage = `${
          localMessage ||
          (error ? this.$t(error.message) : undefined) ||
          this.$t('Erro desconhecido')
        }`
      }

      return {
        logId,
        title: localTitle,
        message: errorMessage,
      }
    },
  },
}

export default alerts
